import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import InputTextfield from '../../../atoms/textfield';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { validateForm } from '../../../utilities/form';
import { classNames } from 'classnames';

function ForgotPasswordForm() {
    const navigate = useNavigate();
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const loginBtnRef = useRef(null);
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });
    const resetLink = window.location.search.replace('?resetLink=',''); //new URLSearchParams(window.location.search).get('resetLink');

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const togglePassword = (passwordRef) => {
        const passwordField = passwordRef.current;
        const passwordInput = passwordField.querySelector('input');
        const passwordIcon = passwordField.querySelector('.password-toggle-icon');
        if (passwordInput.type === "password") {
            passwordInput.type = "text";
            passwordIcon.classList.remove("fa-eye");
            passwordIcon.classList.add("fa-eye-slash");
          } else {
            passwordInput.type = "password";
            passwordIcon.classList.remove("fa-eye-slash");
            passwordIcon.classList.add("fa-eye");
          }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm(formData);
        if(formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Password and confirm password are different !';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            // Form submission logic here
            try {
                loginBtnRef.current.classList.add("button-loading");
                const response = await axios.request({
                    baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
                    url: '/ForgetUser',
                    method: 'post',
                    data: {
                        "useriD_LOGIN": resetLink,
                        "password": formData.password
                    }
                });
                toast("Password changed successfully.")
                loginBtnRef.current.classList.remove("button-loading");
                
                navigate("/signin");
            }
            catch(e) {
                loginBtnRef.current.classList.remove("button-loading");
                toast('Password reset failed, please try again after some time.')
            }
        }
    };

    return (
        <div className="set-password-form-content">
            <div className="set-password-form-logo"></div>
            <div className="set-password-form-label">
                Create New Password
            </div>
            <div className="set-password-form-label set-password-form-label-note">
                You can add new password
            </div>
            <form onSubmit={handleSubmit}>
                <div className="input-container set-password-form-input set-password-form-input-password" ref={passwordRef}>
                    <i className="fa fa-lock icon"></i>
                    <InputTextfield type="password" name="password" placeholder="Password" hideError={true} error={errors.password} onChange={handleChange}/>
                    <i className="password-toggle-icon fa fa-eye icon" onClick={() => togglePassword(passwordRef)}></i>
                </div>
                { errors.password && (
                    <div className="error-message">
                        {errors.password}
                    </div>
                )}
    
                <div className="input-container set-password-form-input set-password-form-input-password" ref={confirmPasswordRef}>
                    <i className="fa fa-lock icon"></i>
                    <InputTextfield type="password" name="confirmPassword" placeholder="Confirm Password" hideError={true} error={errors.confirmPassword} onChange={handleChange}/>
                    <i className="password-toggle-icon fa fa-eye icon" onClick={() => togglePassword(confirmPasswordRef)}></i>
                </div>
                { errors.confirmPassword && (
                    <div className="error-message">
                        {errors.confirmPassword}
                    </div>
                )}
                
                <div className="set-password-form-btn set-password-form-login-btn">
                    <button type="submit" ref={loginBtnRef}>
                        <span className="button__text">Save</span>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ForgotPasswordForm;