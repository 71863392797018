import axios from "axios";
import { baseURL, getCustomersURL, sitesURL } from "../../constants/apiUrl";

export const getSitesData = async (callbackFn, customerId) => {
  try {
    if(customerId) {
      const response = await axios.request({
        baseURL,
        url: `${getCustomersURL}/${customerId}`,
        method: 'get',
      });
      let data = response?.data?.sites;
      data = data?.map(row => ({
        ...row,
        customeR_ID: customerId || row.customeR_ID,
        'locationsCount': row?.locations?.length || 0
      }));
      callbackFn(data); 
    } else {
      const response = await axios.request({
        baseURL,
        url: sitesURL,//customerId ? `${customerSitesURL}/${customerId}` : sitesURL,
        method: 'get',
      });
      let data = response?.data || response;
      data = data?.map(row => ({
        ...row,
        customeR_ID: customerId || row.customeR_ID,
        'locationsCount': row?.locations?.length || 0
      }));
      callbackFn(data); 
    } 
  }
  catch(e) {
    console.log('Something went wrong, please try again later.')
  }
};