export const getPassTemplateIdsFromLocations = (locations) => {
  const passTemplateIds =  new Set();
  locations?.forEach(({pasS_TEMPLATE_ID}) => {
    pasS_TEMPLATE_ID.split(',').forEach(templateID => passTemplateIds.add(templateID))
  });
  return [...passTemplateIds].toString();
}

export const getLocationsPassTemplates = (locations, passTemplatesData) => {
  const passTemplateIds =  getPassTemplateIdsFromLocations(locations).split(',');
  const passTemplates = passTemplatesData.filter(({pasS_NEW_ID}) => passTemplateIds.includes(pasS_NEW_ID));
  return passTemplates;
}

export const getCustomerIdOfPassTemplate = (sites, locations, passTemplateId) => {
  const siteIds =  new Set();
  const customerIds = new Set();
  locations?.forEach(({pasS_TEMPLATE_ID, sitE_ID}) => {
    pasS_TEMPLATE_ID.split(',').forEach(templateID => passTemplateId === templateID && siteIds.add(sitE_ID))
  });

  sites?.forEach(element => {
    if(siteIds.has(parseInt(element.sitE_ID))) {
      customerIds.add(element.customeR_ID);
    }
  });

  return [...customerIds].toString();
}

export const getPassholdersCountFromPassTemplates = (passTemplateIds, classifiedPassHolders) => {
  return passTemplateIds.split(',').reduce((total, passTemplateId) => classifiedPassHolders[passTemplateId] ? total + classifiedPassHolders[passTemplateId]?.length : total, 0);
};

export const getPassholdersCountFromPassLocations = (locations, classifiedPassHolders) => {
  const passTemplateIds = getPassTemplateIdsFromLocations(locations);
  return getPassholdersCountFromPassTemplates(passTemplateIds, classifiedPassHolders);
}

export const getPassholdersFromPassTemplates = (passTemplateIds, classifiedPassHolders) => {
  return passTemplateIds.split(',').reduce((total, passTemplateId) => classifiedPassHolders[passTemplateId] ? [...total, ...classifiedPassHolders[passTemplateId]] : total, []);
};

export const getPassholdersFromPassLocations = (locations, classifiedPassHolders) => {
  const passTemplateIds = getPassTemplateIdsFromLocations(locations);
  return getPassholdersFromPassTemplates(passTemplateIds, classifiedPassHolders);
}

export const getPassSites = (sites, siteIds) => {
  const siteNames = [];
  sites?.forEach(element => {
    if(siteIds.has(parseInt(element.sitE_ID))) {
      siteNames.push(element.sitE_NAME);
    }
  });
  return siteNames.toString()
};

export const getPassLocations = (locations, passTemplateGuid) => {
  const passLocations =  new Set();
  const locationNames = [];
  const siteIds = new Set();
  locations?.forEach(element => {
    if((element.pasS_TEMPLATE_ID === passTemplateGuid) && !passLocations.has(parseInt(element.locatioN_ID))) {
      passLocations.add(parseInt(element.locatioN_ID));
      locationNames.push(element.locatioN_NAME);
      siteIds.add(element.sitE_ID)
    }
  });
  return {
    locationNames,
    siteIds
  }
};

export const parsePassholdersData = (passholders, sites, locations) => {
  const data = passholders?.map(passholder => {
    const passholderObj = {...passholder};
    const passFieldData = Object.values(passholderObj.passFieldData);
    passholderObj.passHolder = passFieldData[0];
    passholderObj.passHolderEmail = passFieldData[1];
    passholderObj.phone = passFieldData[5]; 
    const { locationNames, siteIds } = getPassLocations(locations, passholderObj.passTemplateGuid);
    passholderObj.locationName = locationNames?.toString();
    passholderObj.siteName = getPassSites(sites, siteIds);
    passholderObj.status = parseInt(passholder.numberOfActive) ? 'Active' : 'Inactive';
    return passholderObj;
  });

  return data;
}

export const parsePassesData = (passholders) => {
  const data = passholders?.map(passholder => {
    const passholderObj = {...passholder};
    const passFieldData = Object.values(passholderObj.passFieldData);
    passholderObj.passHolder = passFieldData[7];
    passholderObj.passHolderEmail = passFieldData[4];
    passholderObj.phone = passFieldData[5]; 
    passholderObj.numberPlate = passFieldData[10];
    passholderObj.status = parseInt(passholder.numberOfActive);
    return passholderObj;
  });

  return data;
}