import axios from "axios";
import { passCreatorAPIBaseURL, getAllPassesOfTemplateURL, getPassesOfMultipleTemplatesURLv2 } from "../../constants/apiUrl";

/*
const data = [
  {
    identifier: "8d043735-93f6-46cb-9537-7f2c86cd0b46",
    userProvidedId: "user-789",
    passTemplateGuid: "4175228c-6da4-470c-bde9-c82c75e92721",
    passTemplateName: "Membership Template - All Fields",
    passFieldData: {
      '5b361ad1b82342.33286937': "123456",
      '5b361ad1b82592.54970699': "987654",
      '5b3b435d033579.43705944': "John",
      '675106b5b8ac44.45829931': "Doe",
      '5b361ad1b825d6.00447732': "john.doe@example.com",
      '675106b5b8ad12.83596578': "+1234567890",
      '675106b5b8ad47.50114115': "123 Main St",
      '675106b5b8ad76.63921952': "Jane Doe",
      '675106b5b8adb0.41220332': "1990-01-01",
      '675106b5b8ade4.79412962': "Male",
      '675106b5b8ae19.84220332': "ABC-1234",
      '675106b5b8ae50.44294881': "DL1234567890",
      '675106b5b8ae76.17026451': "Reading, Cycling",
      '675106b5b8aea3.34596682': "Google Search",
      '675106b5b8aed9.89991781': "Billboard Ad"
      },
  },
  {
    identifier: "8d043735-93f6-46cb-9537-7f2c86cd0b46",
    userProvidedId: "user-789",
    passTemplateGuid: "4175228c-6da4-470c-bde9-c82c75e92721",
    passTemplateName: "Membership Template - All Fields",
    passFieldData: {
      '5b361ad1b82342.33286937': "123456",
      '5b361ad1b82592.54970699': "987654",
      '5b3b435d033579.43705944': "John",
      '675106b5b8ac44.45829931': "Doe",
      '5b361ad1b825d6.00447732': "john.doe@example.com",
      '675106b5b8ad12.83596578': "+1234567890",
      '675106b5b8ad47.50114115': "123 Main St",
      '675106b5b8ad76.63921952': "Jane Doe",
      '675106b5b8adb0.41220332': "1990-01-01",
      '675106b5b8ade4.79412962': "Male",
      '675106b5b8ae19.84220332': "ABC-1234",
      '675106b5b8ae50.44294881': "DL1234567890",
      '675106b5b8ae76.17026451': "Reading, Cycling",
      '675106b5b8aea3.34596682': "Google Search",
      '675106b5b8aed9.89991781': "Billboard Ad"
      },
  },
  {
    identifier: "8d043735-93f6-46cb-9537-7f2c86cd0b46",
    userProvidedId: "user-789",
    passTemplateGuid: "4175228c-6da4-470c-bde9-c82c75e92721",
    passTemplateName: "Membership Template - All Fields",
    passFieldData: {
      '5b361ad1b82342.33286937': "123456",
      '5b361ad1b82592.54970699': "987654",
      '5b3b435d033579.43705944': "John",
      '675106b5b8ac44.45829931': "Doe",
      '5b361ad1b825d6.00447732': "john.doe@example.com",
      '675106b5b8ad12.83596578': "+1234567890",
      '675106b5b8ad47.50114115': "123 Main St",
      '675106b5b8ad76.63921952': "Jane Doe",
      '675106b5b8adb0.41220332': "1990-01-01",
      '675106b5b8ade4.79412962': "Male",
      '675106b5b8ae19.84220332': "ABC-1234",
      '675106b5b8ae50.44294881': "DL1234567890",
      '675106b5b8ae76.17026451': "Reading, Cycling",
      '675106b5b8aea3.34596682': "Google Search",
      '675106b5b8aed9.89991781': "Billboard Ad"
      },
  },
  {
    identifier: "8d043735-93f6-46cb-9537-7f2c86cd0b46",
    userProvidedId: "user-789",
    passTemplateGuid: "4175228c-6da4-470c-bde9-c82c75e92721",
    passTemplateName: "Membership Template - All Fields",
    passFieldData: {
      '5b361ad1b82342.33286937': "123456",
      '5b361ad1b82592.54970699': "987654",
      '5b3b435d033579.43705944': "John",
      '675106b5b8ac44.45829931': "Doe",
      '5b361ad1b825d6.00447732': "john.doe@example.com",
      '675106b5b8ad12.83596578': "+1234567890",
      '675106b5b8ad47.50114115': "123 Main St",
      '675106b5b8ad76.63921952': "Jane Doe",
      '675106b5b8adb0.41220332': "1990-01-01",
      '675106b5b8ade4.79412962': "Male",
      '675106b5b8ae19.84220332': "ABC-1234",
      '675106b5b8ae50.44294881': "DL1234567890",
      '675106b5b8ae76.17026451': "Reading, Cycling",
      '675106b5b8aea3.34596682': "Google Search",
      '675106b5b8aed9.89991781': "Billboard Ad"
      },
  },
  {
    identifier: "8d043735-93f6-46cb-9537-7f2c86cd0b46",
    userProvidedId: "user-789",
    passTemplateGuid: "4175228c-6da4-470c-bde9-c82c75e92721",
    passTemplateName: "Membership Template - All Fields",
    passFieldData: {
      '5b361ad1b82342.33286937': "123456",
      '5b361ad1b82592.54970699': "987654",
      '5b3b435d033579.43705944': "John",
      '675106b5b8ac44.45829931': "Doe",
      '5b361ad1b825d6.00447732': "john.doe@example.com",
      '675106b5b8ad12.83596578': "+1234567890",
      '675106b5b8ad47.50114115': "123 Main St",
      '675106b5b8ad76.63921952': "Jane Doe",
      '675106b5b8adb0.41220332': "1990-01-01",
      '675106b5b8ade4.79412962': "Male",
      '675106b5b8ae19.84220332': "ABC-1234",
      '675106b5b8ae50.44294881': "DL1234567890",
      '675106b5b8ae76.17026451': "Reading, Cycling",
      '675106b5b8aea3.34596682': "Google Search",
      '675106b5b8aed9.89991781': "Billboard Ad"
      },
  },
  {
    identifier: "8d043735-93f6-46cb-9537-7f2c86cd0b46",
    userProvidedId: "user-789",
    passTemplateGuid: "4175228c-6da4-470c-bde9-c82c75e92721",
    passTemplateName: "Membership Template - All Fields",
    passFieldData: {
      '5b361ad1b82342.33286937': "123456",
      '5b361ad1b82592.54970699': "987654",
      '5b3b435d033579.43705944': "John",
      '675106b5b8ac44.45829931': "Doe",
      '5b361ad1b825d6.00447732': "john.doe@example.com",
      '675106b5b8ad12.83596578': "+1234567890",
      '675106b5b8ad47.50114115': "123 Main St",
      '675106b5b8ad76.63921952': "Jane Doe",
      '675106b5b8adb0.41220332': "1990-01-01",
      '675106b5b8ade4.79412962': "Male",
      '675106b5b8ae19.84220332': "ABC-1234",
      '675106b5b8ae50.44294881': "DL1234567890",
      '675106b5b8ae76.17026451': "Reading, Cycling",
      '675106b5b8aea3.34596682': "Google Search",
      '675106b5b8aed9.89991781': "Billboard Ad"
      },
  }
];
*/

export const getAllPassesOfTemplate = async (passTemplates, callbackFn) => {
  let passes = [];
  passTemplates.forEach(async passTemplate => {
    try {
      const response = await axios.request({
        baseURL: passCreatorAPIBaseURL,
        url: `${getAllPassesOfTemplateURL}/${passTemplate.identifier}`,
        method: 'get',
      });
      passes = [
        ...passes,
        ...response.results
      ];
    }
    catch(e) {
      console.log('Something went wrong with pass templates fetching, please try again later.')
    }
  });
  
  callbackFn(passes);
};

export const getAllPassesOfMultipleTemplatesV2 = async (passTemplates, callbackFn) => {
  const passTemplateIds = passTemplates.map(({identifier}) => identifier);
  try {
    const response = await axios.request({
      baseURL: passCreatorAPIBaseURL,
      url: `${getPassesOfMultipleTemplatesURLv2}/${passTemplateIds?.toString()}`,
      method: 'get',
    });
    console.log(response?.data);
    callbackFn(response?.data);
  }
  catch(e) {
    console.log('Something went wrong with pass templates fetching, please try again later.')
  }
};

const classifyPassesBasedOnPassTemplates = (passholders) => {
  let classifiedPassHolders = {};
  passholders.forEach(passholder => {
    if(classifiedPassHolders[passholder.passTemplateGuid]) {
      classifiedPassHolders[passholder.passTemplateGuid].push(passholder);
    } else {
      classifiedPassHolders[passholder.passTemplateGuid] = [passholder];
    }
  });
  return classifiedPassHolders;
}

export const getAllPassesOfMultipleLocationsTemplatesV2 = async (locations, callbackFn) => {
  const passTemplateIds = new Set();
  locations.forEach(({pasS_TEMPLATE_ID}) => {
    const passTemplates = pasS_TEMPLATE_ID.split(',');
    passTemplates.forEach(passTemplate => passTemplateIds.add(passTemplate));
  });
  try {
    const response = await axios.request({
      baseURL: passCreatorAPIBaseURL,
      url: `${getPassesOfMultipleTemplatesURLv2}/${[...passTemplateIds].toString()}`,
      method: 'get',
    });
    const classifiedPassHolders =  classifyPassesBasedOnPassTemplates(response?.data);
    console.log(response?.data, classifiedPassHolders);
    callbackFn(response?.data, classifiedPassHolders);
  }
  catch(e) {
    console.log('Something went wrong with pass templates fetching, please try again later.')
  }
};