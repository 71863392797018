import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { BreadcrumbContext } from '../Layout/Layout';
import TableConfig from './passesTableConfig.json';
import { addGridData, gridRowSelected } from './passesSlice';
import Table from '../../atoms/Table/Table';
import classNames from 'classnames';
import { getPassholdersFromPassLocations } from '../../utilities/pass';
import { parsePassesData } from '../../utilities/pass';

function Passes({viewSitePasses}) {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { header, visibleColumns, exportableColumns } = TableConfig;
  const passes = useSelector(state => state.passes.passes);
  const [originalGridData, setOriginalGridData] = useState(passes);
  const [gridData, setGridData] = useState(passes);
  const [selectedFilters, setSelectedFilters] = useState({});
  const classifiedPassholders = useSelector(state => state.passholder.classifiedPassholders);
  const [passTemplates, setPassTemplates] = useState([]);
  const selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer') || null);
  const selectedLocation = JSON.parse(localStorage.getItem('selectedLocation') || null);
  const selectedLocations = JSON.parse(localStorage.getItem('locations') || null);
  const selectedSite = JSON.parse(localStorage.getItem('selectedSite') || {});

  const updateBreadcrumb = () => {
    setCurrentBreadcrumb(viewSitePasses ? [
      {
        "linkText": "All Customers",
        "path": "/customers"
      },
      {
        "linkText": selectedCustomer?.customeR_FIRSTNAME || '-',
        "path": "/customers/view"
      },
      {
        "linkText": selectedSite?.sitE_NAME || '-',
        "path": "/customers/view/locations"
      }
    ] : [
      {
        "linkText": "All Customers",
        "path": "/customers"
      },
      {
        "linkText": selectedCustomer?.customeR_FIRSTNAME || '-',
        "path": "/customers/view"
      },
      {
        "linkText": selectedSite?.sitE_NAME || '-',
        "path": "/customers/view/locations"
      },
      {
        "linkText": selectedLocation?.locatioN_NAME,
        "path": "/passes"
      }
    ]);
  };

  const getPassTemplatesFromPasses = (passes) => {
    const passTemplateGuids = [];
    const passTemplates = [];
    passes.forEach(({passTemplateGuid, passTemplateName}) => {
      !passTemplateGuids.includes(passTemplateGuid) && passTemplateGuids.push(passTemplateGuid) && passTemplates.push({passTemplateGuid, passTemplateName})
    });
    console.log(passTemplates);
    setPassTemplates(passTemplates);
  }

  const getPassesData = () => {
    const locations = viewSitePasses ? [...selectedLocations] : [selectedLocation];
    const data = parsePassesData(getPassholdersFromPassLocations(locations, classifiedPassholders));
    getPassTemplatesFromPasses(data);
    dispatch(addGridData(data));
  };

  useEffect(() => {
    updateBreadcrumb();
    return () => {}
  }, []);

  useEffect(() => {
    classifiedPassholders && getPassesData();
  }, []);

  useEffect(() => {
    setOriginalGridData(passes);
    setGridData(passes);
  }, [passes]);

  const viewEditRow = (opType, row) => {
    localStorage.setItem('selectedPass', JSON.stringify(row));
    dispatch(gridRowSelected(row));
    opType === 'view' ? navigate(`/passsessions`) : console.log('no edit');//navigate(`/passes/edit`);
  }

  const filterData = (filterByAttr, selectedValue) => {
    setSelectedFilters({
      [filterByAttr]: selectedValue
    });

    if(!selectedValue) return setGridData(originalGridData);

    const searchData = [];
    originalGridData.forEach((row) => {
      row[filterByAttr]?.toString()?.includes(selectedValue) && searchData.push(row);
    });
    setGridData(searchData);
  };

  const generateDataColumn = (key, value, row) => {
    if(key === "actions") return (
      <div className="passes-grid-row-actions">
        <button onClick={() => viewEditRow('view', row)}>
          <img src="/assets/customers/view.png"/>
        </button>
      </div>
    );

    if(key === 'status') {
      return (
        <div className={classNames({"passes-grid-status": true, "passes-grid-status-active": parseInt(value)})}>{parseInt(value) ? 'Active': 'Inactive'}</div>
      )
    }

    return value;
  }

  const clearSelectedFilter = () => {
    setSelectedFilters({
      passTemplateGuid: '',
      status: ''
    });
    setGridData([...originalGridData]);
  }

  const renderTableFilters = () => (
    <div className="passes-filter">
      <label>Filter by</label>
      <select name="siteFilter" value={selectedFilters.passTemplateGuid} id="state" onChange={(e) => filterData('passTemplateGuid', e.target.value)}>
        <option value="">Pass Template</option>
        {
          passTemplates.map(passTemplate => (
            <option value={passTemplate.passTemplateGuid}>{passTemplate.passTemplateName}</option>
          ))
        }
      </select>

      <label>Filter by</label>
      <select name="siteFilter" value={selectedFilters.status} id="state" onChange={(e) => filterData('status', e.target.value)}>
        <option value="">Status</option>
        <option value="1">{'Active'}</option>
        <option value="0">{'In Active'}</option>
      </select>
      <button className="passes-filter-clear" onClick={clearSelectedFilter}>Clear Selected</button>
    </div>
  )

  return (
    <div className="passes">
      <Table
        renderTableFilters={renderTableFilters}
        TableTitle={`All Passes`}
        header={header}
        visibleColumns={visibleColumns}
        originalData={originalGridData}
        data={gridData}
        rowId={'identifier'}
        exportableColumns={exportableColumns}
        generateDataColumn={generateDataColumn}
      />
    </div>
  );
};

export default Passes;