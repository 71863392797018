import { createSlice } from '@reduxjs/toolkit'

const sitesSlice = createSlice({
  name: 'sites',
  initialState: {
    sites: [],
    updateSites: 0
  },
  reducers: {
    sitesAdded(state, action) {
      state.sites = [...action.payload]
    },
    sitesUpdated(state, action) {
      state.updateSites = Math.floor(Math.random()*10000)
    },
  },
})

export const { sitesAdded, sitesUpdated } = sitesSlice.actions
export default sitesSlice.reducer