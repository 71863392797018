import { useContext, useEffect, useState } from 'react';
import { BreadcrumbContext } from '../../Layout/Layout';
import { useNavigate } from "react-router-dom";
import AddCustomer from "../AddCustomer";
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCustomerData } from './../AddCustomer/customerAPI';
import { getUserData } from '../../../utilities/app';

function EditCustomer() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const { isCustomerUser  } = getUserData();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const navigate = useNavigate();
  const selectedCustomerId = localStorage.getItem('selectedCustomerId');

  const updateBreadcrumb = (customerName) => {
    if(isCustomerUser) {
      setCurrentBreadcrumb([
        {
          "linkText": "Settings",
          "path": "/profile"
        },
        {
          "linkText": 'Profile',
          "path": "/profile"
        },
        {
          "linkText": "Edit",
          "path": "/profile"
        }
      ]);
    } else {
      setCurrentBreadcrumb([
        {
          "linkText": "All Customers",
          "path": "/customers"
        },
        {
          "linkText": customerName,
          "path": "/customers/view"
        },
        {
          "linkText": "Edit",
          "path": "/customers/edit"
        }
      ]);
    }
  }

  const fetchCustomerData = async () => {
    if(!selectedCustomerId) {
      navigate('/customers');
    };

    try {
      getCustomerData(selectedCustomerId, data => {
        updateBreadcrumb(data?.customeR_FIRSTNAME);
        setSelectedCustomer(data);
      });
    }
    catch(e) {
      toast('Something went wrong, please try again later.')
    }
  };
  useEffect(() => {
    updateBreadcrumb('Customer');
    fetchCustomerData();
  }, []);

  return (
    <div className="edit-customer">
      <AddCustomer isEditCustomer={true} selectedCustomer={selectedCustomer}/>
    </div>
  )
};

export default EditCustomer;