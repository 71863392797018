import axios from "axios";
import { getCustomerDropdownDataURL, baseURL, getPassTypesURL, smsBaseURL, sendSMSURL, getPassTemplatesURL, passCreatorAPIBaseURL } from "../constants/apiUrl";

export const getCustomersDropdownData = async (callbackFn) => {
  try {
    const response = await axios.request({
        baseURL,
        url: getCustomerDropdownDataURL,
        method: 'get',
    });
    callbackFn(response?.data);
  }
  catch(e) {
    console.log('Something went wrong, please try again later.')
  }
};

export const getPassTypes = async (callbackFn) => {
  try {
    const response = await axios.request({
        baseURL: baseURL,
        url: getPassTypesURL,
        method: 'get',
    });
    callbackFn(response?.data);      
  }
  catch(e) {
    console.log('Something went wrong with pass templates fetching, please try again later.')
  }
};

export const getPassCreatorTemplates = async (callbackFn) => {
  try {
    const response = await axios.request({
        baseURL: passCreatorAPIBaseURL,
        url: getPassTemplatesURL,
        method: 'get',
    });
    callbackFn(response);      
  }
  catch(e) {
    console.log('Something went wrong with pass templates fetching, please try again later.')
  }
};

export const sendSMS = async (to, body, callbackFn) => {
  try {
    const response = await axios.request({
        baseURL: smsBaseURL,
        url: sendSMSURL,
        method: 'post',
        data: {
          to: `+${to}`,
          body
        }
    });
    callbackFn(response?.data);      
  }
  catch(e) {
    console.log('Something went wrong with pass templates fetching, please try again later.')
  }
};

