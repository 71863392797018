import { configureStore } from '@reduxjs/toolkit'
import sitesReducers from '../components/Sites/sitesSlice';
import customersReducres from '../components/Customers/customerSlice';
import appReducers from '../appSlices';
import locationsReducers from '../components/Locations/locationsSlice';
import passholderReducers from '../components/Passholders/passholdersSlice';
import passesReducers from '../components/Passes/passesSlice';
import passSessionsReducers from '../components/PassSessions/passSessionsSlice';
import bookingReducers from '../components/Bookings/bookingsSlice';
import autoReportingReducers from '../components/Reporting/AutoReporting/autoReportingSlice';

export const store = configureStore({
  reducer: {
    site: sitesReducers,
    customer: customersReducres,
    location: locationsReducers,
    passholder: passholderReducers,
    passes: passesReducers,
    passSessions: passSessionsReducers,
    appData: appReducers,
    booking: bookingReducers,
    autoReporting: autoReportingReducers
  },
})