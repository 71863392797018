import { useContext, useEffect, useState } from 'react';
import { BreadcrumbContext } from "../Layout/Layout";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { customersAdded } from '../Customers/customerSlice';
import { sitesAdded } from '../Sites/sitesSlice';
import { locationsAdded } from '../Locations/locationsSlice';
import { getLocationsData, getCustomerLocationsData } from './../Locations/locationsAPI';
import { getSitesData } from '../Sites/sitesAPI';
import { getCustomersDropdownData, getPassTypes, getPassCreatorTemplates } from '../dataAPI';
import { customersDropdownDataAdded, passTemplatesAdded } from '../../appSlices';
import { getAllPassesOfMultipleLocationsTemplatesV2 } from '../Passholders/passCreatorAPI';
import { passholdersAdded, classifiedPassholdersAdded } from '../Passholders/passholdersSlice';
import { customerSelected } from '../Customers/customerSlice';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { getUserData } from '../../utilities/app';
import { getAllCustomersData } from '../Customers/AddCustomer/customerAPI';

function Dashboard() {
    const navigate = useNavigate();
    const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
    const selectedCustomer = useSelector(state => state.customer.selectedCustomer);
    const { userType, status, customerID, isCustomerUser } = getUserData();

    useEffect(() => {
      !userType && navigate('/signin');
    }, [userType]);

    const [dashboardData, setDashboardData] = useState([]);
    const customers = useSelector(state => state.customer.customers);
    const sites = useSelector(state => state.site.sites);
    const locations = useSelector(state => state.location.locations);
    const passholders = useSelector(state => state.passholder.passholders);
    const [locationsData, setLocationsData] = useState(locations);
    const dispatch = useDispatch();
    const [customerLoader, setCustomerLoader] = useState(true);
    const [sitesLoader, setSitesLoader] = useState(true);
    const [locationsLoader, setLocationsLoader] = useState(true);
    const [passsholdersLoader, setpasssholdersLoader] = useState(true);

    const getCustomersData = () => {
      getAllCustomersData((response) => {
        const data = response?.map(data => ({
          ...data,
          'contact_Name': data.customerContacts && data.customerContacts.length ? data.customerContacts[0].contacT_NAME : '',
          'status': data.activE_IND ? 'Approved': 'Pending'
        }));
        dispatch(customersAdded(data));
        setCustomerLoader(false);
      });
    }

    useEffect(() => {
      setCurrentBreadcrumb([
        {
          "linkText": "Home",
          "path": "/home"
        }
      ]);
      getSitesData(data => {
        dispatch(sitesAdded(data));
        setSitesLoader(false);
      }, customerID);

      if(isCustomerUser) {
        getCustomerLocationsData(
          customerID,
          (locations, customerData) => {
            dispatch(customerSelected(customerData))
            setLocationsData(locations);
            dispatch(locationsAdded(locations));
            setLocationsLoader(false);
          }
        );
      } else {
        getCustomersData();
        getCustomersDropdownData(data => dispatch(customersDropdownDataAdded(data)));
        getLocationsData(data => {
          dispatch(locationsAdded(data));
          setLocationsLoader(false);
        }, customerID);
      }
      getPassTypes(data => dispatch(passTemplatesAdded(data)));
      //getPassCreatorTemplates(data => console.log(data));
    }, []);

    useEffect(() => {
      if(!locations.length) return;
      setLocationsData(locations);
      getAllPassesOfMultipleLocationsTemplatesV2(locations, (passHolders, classifiedPassHolders) => {
        const passes = passHolders?.map(row => ({
          ...row,
          'status': row.status ? 'Active': 'Inactive'
        }));
        setpasssholdersLoader(false);
        dispatch(passholdersAdded(passes));
        dispatch(classifiedPassholdersAdded(classifiedPassHolders));
      });
    }, [locations]);

    const updateCardData = () => {
      if(isCustomerUser) {
        setDashboardData([
          {
            name: 'Sites',
            count: sites?.length || 0,
            path: '/sites',
            imagePath: '/assets/dashboard/sites.png',
            showLoader: sitesLoader
          },
          {
            name: 'Locations',
            count: locationsData?.length || 0,
            path: '/locations',
            imagePath: '/assets/dashboard/locations.png',
            showLoader: locationsLoader
          },
          {
            name: 'Passes',
            count: passholders?.length,
            path: '/passholders',
            imagePath: '/assets/dashboard/passes.png',
            showLoader: passsholdersLoader
          }
        ]);
      } else {
        setDashboardData([
          {
            name: 'Customer',
            count: customers?.length || 0,
            path: '/customers',
            imagePath: '/assets/dashboard/customers.png',
            showLoader: customerLoader
          },
          {
            name: 'Sites',
            count: sites?.length || 0,
            path: '/sites',
            imagePath: '/assets/dashboard/sites.png',
            showLoader: sitesLoader
          },
          {
            name: 'Locations',
            count: locationsData?.length || 0,
            path: '/locations',
            imagePath: '/assets/dashboard/locations.png',
            showLoader: locationsLoader
          },
          {
            name: 'Passes',
            count: passholders?.length,
            path: '/passholders',
            imagePath: '/assets/dashboard/passes.png',
            showLoader: passsholdersLoader
          }
        ]);
      }
    };

    useEffect(() => {
      updateCardData();
    }, [customers?.length, sites?.length, locationsData?.length, passholders?.length]);

    useEffect(() => {
      updateCardData();
    }, [customerLoader, sitesLoader, locationsLoader, passsholdersLoader]);

    const generateCards = () => dashboardData.map((data, index) => (
      <div key={`dashboard-card${index}`} className={classNames({"dashboard-card": true, "dashboard-card-long": isCustomerUser})}>
        <div className="dashboard-card-content">
          <div className="dashboard-card-details">
            <div className="dashboard-card-name">{data.name}</div>
            <div className={classNames({"dashboard-card-count": true, "dashboard-card-loading": data.showLoader})}>{!data.showLoader && data.count}</div>
            <div className="dashboard-card-link">
              <Link to={data.path}>
                View All
              </Link>
          </div>
        </div>
        <div className="dashboard-card-img">
          <img src={data.imagePath}/>
        </div>
        </div>
        
      </div>
    ));

    return (
      <div className="dashboard">
        <div className="dashboard-title">Dashboard</div>
        <div className="dashboard-cards">
          { status ? generateCards() : null }
        </div>
      </div>
    );
};

export default Dashboard;