//Dependencies
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
//Components
import { BreadcrumbContext } from '../../Layout/Layout';
import TableConfig from './autoReportingTableConfig.json';
import Table from '../../../atoms/Table/Table';
//API
import { getAutoReporting, deleteScheduledReport } from './autoReportingAPI';
import { getAllCustomersData } from '../../Customers/AddCustomer/customerAPI';
//Utilities
import { getUserData } from '../../../utilities/app';
//slices
import { autoReportingAdded, autoReportingSelected } from './autoReportingSlice';
import { customersAdded } from '../../Customers/customerSlice';
//Utilities

function AutoReporting() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { header, visibleColumns, exportableColumns } = TableConfig;
  const reportings = useSelector(state => state.autoReporting.reportings);
  const [originalGridData, setOriginalGridData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const customers = useSelector(state => state.customer.customers);
  const [customersData, setCustomersData] = useState(customers);
  const [selectedFilters, setSelectedFilters] = useState({
    customeR_ID: 'all'
  });

  const { userType, customerID, isCustomerUser } = getUserData();
  
  useEffect(() => {
    !userType && navigate('/signin');
  }, [userType]);

  const parseData = data => {
    return data.map(row => {
      return {
        ...row,
      }
    })
  }

  const getAutoReportingData = () => {
    setLoadingData(true);
    getAutoReporting(
      (data) => {
        setLoadingData(false);
        dispatch(autoReportingAdded(data));
        const formatedData = parseData([...data]);
        setOriginalGridData(formatedData);
        setGridData(formatedData);
      }
    );
  };

  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "Reporting",
        "path": "/reports"
      },
      {
        "linkText": "Auto Reporting",
        "path": "/reports"
      }
    ]);
    //(!reportings || !reportings.length) && getAutoReportingData();
    (!customers || !customers.length) && getAllCustomersData(response => {
        dispatch(customersAdded(response));
        setCustomersData(response);
    });
  }, []);

  useEffect(() => {
    const data = parseData(reportings);
    setGridData(data);
    setOriginalGridData(data);
  }, [reportings]);

  const handleAddAction = () => navigate('/reports/schedule');

  const viewEditReporting = (opType, row) => {
    return;
    localStorage.setItem('selectedReporting', JSON.stringify(row));
    dispatch(autoReportingSelected(row));
    opType === 'edit' ? navigate(`/reporting/edit`) : navigate(`/reporting/view`);
  }

  const deleteReporting = async (row) => {
    const { reportName, reportingID } = row;
    if (window.confirm(`Do you like to delete the report - ${reportName}?`)) {
      return;
      deleteScheduledReport(reportingID, () => {
        getAutoReportingData();
        toast(`Report ${reportName} deleted successfully !`)
      })
    }
  }

  const generateDataColumn = (key, value, row) => {
    if(key === "actions") return (
      <div className="customers-grid-row-actions">
        <button onClick={() => viewEditReporting('view', row)}>
          <img src="/assets/customers/view.png"/>
        </button>
        <button onClick={() => viewEditReporting('edit', row)}>
          <img src="/assets/customers/edit.png"/>
        </button>
        <button onClick={() => deleteReporting(row)}>
          <img src="/assets/customers/delete.png"/>
        </button>
      </div>
    );
    return value;
  }

  const handleCustomerChange = (event) => {
      const customeR_ID = event.target.value;
      setSelectedFilters({
        customeR_ID
      });
      if(!customeR_ID) return;
      if(customeR_ID === 'all') {
          setGridData([...originalGridData]);
      } else {
        const searchData = [];
        originalGridData.forEach((row) => {
          row.customeR_ID?.toString()?.includes(customeR_ID) && searchData.push(row);
        });
        setGridData([...searchData]);
      }
  };

  const clearSelectedFilter = () => {
    setSelectedFilters({
      customeR_ID: 'all'
    });
    setGridData([...originalGridData]);
  }

  const renderTableFilters = () => (
    <div className="auto-reporting-filter">
      <label>Filter by</label>
      <select value={selectedFilters?.customeR_ID} name="customeR_ID" id="customeR_ID" onChange={handleCustomerChange}>
          <option value="">Select Customer*</option>
          <option value="all">All Customers</option>
          {
              customersData.map((customer, index) => (
                  <option key={`customeR_ID${index}`} value={customer.customeR_ID} selected>{customer.customeR_FIRSTNAME}</option>
              ))
          }
      </select>
      <button className="auto-reporting-filter-clear" onClick={clearSelectedFilter}>Clear Selected</button>
    </div>
  )

  return (
    <div className="auto-reporting">
      <Table
        renderTableFilters={renderTableFilters}
        handleAddAction={handleAddAction}
        TableTitle={`Auto Reporting`}
        header={header}
        visibleColumns={visibleColumns}
        originalData={originalGridData}
        data={gridData}
        rowId={'reportingID'}
        exportableColumns={exportableColumns}
        generateDataColumn={generateDataColumn}
        loadingData={loadingData}
      />
    </div>
  );
};

export default AutoReporting;