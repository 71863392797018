import { createSlice } from '@reduxjs/toolkit'

//const passTemplates = [{"identifier":"4175228c-6da4-470c-bde9-c82c75e92721","name":"Membership Template - All Fields"},{"identifier":"a415c562-b56f-40c6-bf96-20e397369af2","name":"Event Ticket Template - All Fields"},{"identifier":"30b59282-4c24-4aee-8bd3-e6057b35a5d3","name":"Store Card Template - All Fields"},{"identifier":"06e86ae5-02a0-4427-8059-e12fb2f3c363","name":"Parking Pass Template - All Fields"},{"identifier":"029212a7-65a8-4151-849a-b4e15eaeb04f","name":"Gift Card Template - All Fields"},{"identifier":"65a41db8-e2ac-4b6d-83fb-e14cfe81dccb","name":"Sumit"},{"identifier":"1e683a98-15fd-402f-bec8-75185ba98b84","name":"Membership VIP Pass Template"},{"identifier":"79800f86-0b82-42b2-befc-7074d17851dc","name":"Event Demo 2"},{"identifier":"aed4db99-7618-4e8c-bf56-44226d77f3da","name":"Event Demo"},{"identifier":"eae68632-700d-4778-94c3-18f9d20bb1be","name":"Example template"}];

const appSlice = createSlice({
  name: 'appData',
  initialState: {
    countries: [],
    customers: [],
    passTemplates: [],
  },
  reducers: {
    countriesAdded(state, action) {
      state.countries = [...action.payload];
    },
    customersDropdownDataAdded(state, action) {
      state.customers = [...action.payload];
    },
    passTemplatesAdded(state, action) {
      state.passTemplates = [...action.payload];
    },
  },
})

export const { countriesAdded, customersDropdownDataAdded, passTemplatesAdded } = appSlice.actions
export default appSlice.reducer