import { createSlice } from '@reduxjs/toolkit'
import TableConfig from './passholdersTableConfig.json';

const { rows } = TableConfig;

const passholdersSlice = createSlice({
  name: 'passholders',
  initialState: {
    passholders: [],
    classifiedPassholders: [],
    updatePassholder: 1,
    selectedPassholder: {}
  },
  reducers: {
    passholdersAdded(state, action) {
      state.passholders = [...action.payload];
    },
    passholdersUpdated(state, action) {
      state.updatePassholder = Math.floor(Math.random()*10000);
    },
    passholderselected(state, action) {
      state.selectedPassholder = {...action.payload}
    },
    classifiedPassholdersAdded(state, action) {
      state.classifiedPassholders = {...action.payload};
    },
  },
})

export const { passholdersAdded, passholdersUpdated, passholderselected, classifiedPassholdersAdded } = passholdersSlice.actions
export default passholdersSlice.reducer