import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import InputTextfield from '../../atoms/textfield';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { validateForm } from '../../utilities/form';
import classNames from 'classnames';

function SignInForm() {
    const navigate = useNavigate();
    const passwordRef = useRef(null);
    const loginBtnRef = useRef(null);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const togglePassword = () => {
        const passwordField = passwordRef.current;
        const passwordInput = passwordField.querySelector('input');
        const passwordIcon = passwordField.querySelector('.password-toggle-icon');
        if (passwordInput.type === "password") {
            passwordInput.type = "text";
            passwordIcon.classList.remove("fa-eye");
            passwordIcon.classList.add("fa-eye-slash");
          } else {
            passwordInput.type = "password";
            passwordIcon.classList.remove("fa-eye-slash");
            passwordIcon.classList.add("fa-eye");
          }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm(formData, {email: /\S+@\S+\.\S+/});
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            // Form submission logic here
            try {
                loginBtnRef.current.classList.add("button-loading");
                const response = await axios.request({
                    baseURL: 'https://api-vrfid-id.azurewebsites.net/api',
                    url: '/Login',
                    method: 'post',
                    data: {
                        "username": formData.email,
                        "password": formData.password
                    }  
                });
                if(!response || !response?.data) {
                    loginBtnRef.current.classList.remove("button-loading");
                    toast('Login failed, please check email and password.');
                    return;
                }
                toast("Login successfull.")
                loginBtnRef.current.classList.remove("button-loading");
                localStorage.setItem('userData', JSON.stringify({
                    userType: response.data.admiN_IND ? 'admin' : 'customer',
                    status: !response.data.admiN_IND ? parseInt(response.data.logiN_ATTEMPTS || 0) : 1,
                    name: response.data.firsT_NAME || response.data.companyName || response.data.middlE_NAME,
                    customerId: response.data.emaiL_ID2,
                    logO_IMAGE: response.data.logO_IMAGE,
                    useriD_LOGIN: response?.data?.useriD_LOGIN,
                    token: response?.data?.password
                }));
                
                navigate("/dashboard");
            }
            catch(e) {
                loginBtnRef.current.classList.remove("button-loading");
                toast('Login failed, please check email and password.')
            }
        }
    };

    return (
        <div className="signin-form-content">
            <div className="signin-form-logo"></div>
            <div className="signin-form-label">
                Login To Your Account
            </div>
            <form onSubmit={handleSubmit}>
                <div className={classNames({'form-input-error': errors.email, 'input-container signin-form-input': true})}>
                    <i className="fa fa-envelope icon"></i>
                    <InputTextfield type="text" name="email" placeholder="Email*" hideError={true} error={errors.email} onChange={handleChange}/>
                </div>
                { errors.email && (
                    <div className="error-message">
                        {errors.email}
                    </div>
                )}
                <div className="input-container signin-form-input signin-form-input-password" ref={passwordRef}>
                    <i className="fa fa-lock icon"></i>
                    <InputTextfield type="password" name="password" placeholder="Password*" hideError={true} error={errors.password} onChange={handleChange}/>
                    <i className="password-toggle-icon fa fa-eye icon" onClick={togglePassword}></i>
                </div>
                { errors.password && (
                    <div className="error-message">
                        {errors.password}
                    </div>
                )}
                
                <div className="signin-form-forgot-link">
                    <Link to="/forgotpassword">Forgot Password</Link>
                </div>
                <div className="signin-form-btn signin-form-login-btn">
                    <button type="submit" ref={loginBtnRef}>
                        <span className="button__text">Login</span>
                    </button>
                </div>
            </form>
            <div className="signin-form-or-label">
            <h3><span>Or</span></h3>
            </div>
            <div className="signin-form-btn signin-form-signup-btn">
                <Link to="/signup">
                    <button>
                        Sign Up
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default SignInForm;