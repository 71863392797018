import { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "../Layout/Layout";
import Table from "../../atoms/Table";
import TableConfig from './passholderDetailsTableConfig.json';

function PassholderDetails() {
    const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
    const selectedPassholder = JSON.parse(localStorage.getItem('selectedPassholder'));
    const { header, visibleColumns, exportableColumns } = TableConfig;
    const [originalGridData, setOriginalGridData] = useState([]);
    const [gridData, setGridData] = useState([]);

    const prepareTableData = () => {
        const {
            placesUsedAt,
            passFieldData,
            ...passHolderInfo
        } = selectedPassholder;
        const data = Object.keys(passHolderInfo).map((key) => ({
            data_type: key,
            data_value: typeof passHolderInfo[key] === 'object' ? JSON.stringify(passHolderInfo[key]) : passHolderInfo[key]?.toString()
        }));
        setOriginalGridData(data);
        setGridData(data);
    }

    useEffect(() => {
        setCurrentBreadcrumb([
            {
                "linkText": "Customers",
                "path": "/customers"
            },
            {
                "linkText": "All Passholders",
                "path": "/passholders"
            },
          {
            "linkText": selectedPassholder?.identifier,
            "path": "/passholdersview"
          }
        ]);
        prepareTableData();
        return () => {}
    }, []);

    const renderPassHolderInfo = () => {
        const {
            placesUsedAt,
            passFieldData,
            passData,
            ...passHolderInfo
        } = selectedPassholder;
        return (
            <>
                {
                    Object.keys(passHolderInfo).map((key) => (
                            <div className="pass-details-heading-text">
                                {key}: {passHolderInfo[key]}
                            </div>
                    ))
                }
                <div className="pass-details-heading-text">
                    Pass data : <span dangerouslySetInnerHTML={{ __html: passData.replaceAll('\n', '<div style="margin-bottom: 10px"></div>') }}></span>
                </div>
            </>
        )
    };

    const generateDataColumn = (key, value, row) => {
        console.log(key, row);
        if(value.includes('/qr/')) {
            return <img src={selectedPassholder.qrCodeImage} width="10%"/>
        }
        if(value.includes('http')) {
            return <a href={value}>{value}</a>
        }
        if(key === 'data_value' && row.data_type === 'passData') {
            console.log(row.passData);
            return (
                <div className="pass-details-heading-text">
                    <span dangerouslySetInnerHTML={{ __html: value.replaceAll('\n', '<br/>') }}></span>
                </div>
            )
        }
        return value;
    }

    return (
        <div className="pass-details">
            <Table
                TableTitle={`Pass Holder Details`}
                header={header}
                visibleColumns={visibleColumns}
                originalData={originalGridData}
                data={gridData}
                rowId={'data_type'}
                exportableColumns={exportableColumns}
                generateDataColumn={generateDataColumn}
            />
        </div>
    )
}

export default PassholderDetails;