import { useEffect, useState } from "react";

export default function Multiselect({
  passTemplatesData,
  selectedPassTemplates,
  name
}) {
  let selectedTemplates = {};
  const initialiseSelectedPassTemplates = () => {
    selectedPassTemplates.split(',').forEach(passTemplate => {
      selectedTemplates[passTemplate] = true;
    });
    sessionStorage.setItem('selectedTemplates', JSON.stringify(selectedTemplates));
  }
  useEffect(() => {
    selectedPassTemplates && initialiseSelectedPassTemplates();
    return () => sessionStorage.setItem('selectedTemplates', JSON.stringify(null));
  }, [selectedPassTemplates]);

  const handleOnChange = (value, isSelected) => {
    let selected = {...JSON.parse(sessionStorage.getItem('selectedTemplates'))};
    if(!isSelected) {
      selected[value] = true;
    } else {
      delete selected[value];
    }
    sessionStorage.setItem('selectedTemplates', JSON.stringify(selected));
    document.getElementById(name).value = Object.keys(selected).toString();
  }

  const getPassTemplatesNamesFromIds = (selected) => {
    const passNames = [];
    passTemplatesData.forEach(({pasS_NEW_ID, pasS_NAME}) => Object.keys(selected).includes(pasS_NEW_ID) && passNames.push(pasS_NAME));
    return passNames?.toString();
  }

  const MultiselectDropdown = () => {
    var config={
      search:true,
      height:'15rem',
      placeholder:'Select Pass Types',
      txtSelected:'Pass Templates Selected',
      txtAll:'All',
      txtRemove: 'Remove',
      txtSearch:'search',
    };
    function newEl(tag,attrs){
      var e=document.createElement(tag);
      if(attrs!==undefined) Object.keys(attrs).forEach(k=>{
        if(k==='class') { 
          const temp = Array.isArray(attrs[k]) ? attrs[k].forEach(o=>o!==''?e.classList.add(o):0) : (attrs[k]!==''?e.classList.add(attrs[k]):0)
        }
        else if(k==='style'){  
          Object.keys(attrs[k]).forEach(ks=>{
            e.style[ks]=attrs[k][ks];
          });
         }
        else if(k==='text'){attrs[k]===''?e.innerHTML='&nbsp;':e.innerText=attrs[k]}
        else e[k]=attrs[k];
      });
      return e;
    }
  
    
    document.querySelectorAll("select[multiple]").forEach((el,k)=>{
      
      //var div=newEl('div',{class:'multiselect-dropdown',style:{width:config.style?.width??el.clientWidth+'px',padding:config.style?.padding??''}});
      var div = document.querySelector('.multiselect-dropdown')
      el.style.display='none';
      el.parentNode.insertBefore(div,el.nextSibling);
      var listWrap=newEl('div',{class:'multiselect-dropdown-list-wrapper'});
      var list=newEl('div',{class:'multiselect-dropdown-list',style:{height:config.height, background: '#16171b'}});
      var search=newEl('input',{class:['multiselect-dropdown-search'].concat([config.searchInput?.class??'form-control']),style:{width:'100%',display:el.attributes['multiselect-search']?.value==='true'?'block':'none'},placeholder:config.txtSearch});
      listWrap.appendChild(search);
      div.appendChild(listWrap);
      listWrap.appendChild(list);
  
      el.loadOptions=()=>{
        list.innerHTML='';
        
        if(el.attributes['multiselect-select-all']?.value=='true'){
          var op=newEl('div',{class:'multiselect-dropdown-all-selector'})
          var ic=newEl('input',{type:'checkbox'});
          op.appendChild(ic);
          op.appendChild(newEl('label',{text:config.txtAll, style:{margin: '0 10px'}}));
    
          op.addEventListener('click',(e)=>{
            op.classList.toggle('checked');
            op.querySelector("input").checked=!op.querySelector("input").checked;
            
            var ch=op.querySelector("input").checked;
            list.querySelectorAll(":scope > div:not(.multiselect-dropdown-all-selector)")
              .forEach(i=>{if(i.style.display!=='none'){i.querySelector("input").checked=ch; i.optEl.selected=ch}});
    
            el.dispatchEvent(new Event('change'));
          });
          ic.addEventListener('click',(ev)=>{
            ic.checked=!ic.checked;
          });
          el.addEventListener('change', (ev)=>{
            let itms=Array.from(list.querySelectorAll(":scope > div:not(.multiselect-dropdown-all-selector)")).filter(e=>e.style.display!=='none')
            let existsNotSelected=itms.find(i=>!i.querySelector("input").checked);
            if(ic.checked && existsNotSelected) ic.checked=false;
            else if(ic.checked==false && existsNotSelected===undefined) ic.checked=true;
          });
    
          list.appendChild(op);
        }
  
        Array.from(el.options).map(o=>{
          var op=newEl('div',{class:o.selected?'checked':'', optEl:o, dataValue: o.value, style:{display: 'flex'}})
          var ic=newEl('input',{type:'checkbox',value: o.value, checked:o.selected});
          op.appendChild(ic);
          op.appendChild(newEl('label',{text:o.text, style:{margin: '0 10px'}}));
  
          op.addEventListener('click',function(handleOnChange) {
            return ()=>{
              console.log(o.value);
              handleOnChange(o.value, op.querySelector("input").checked)
              op.classList.toggle('checked');
              op.querySelector("input").checked=!op.querySelector("input").checked;
              op.optEl.selected=!!!op.optEl.selected;
              el.dispatchEvent(new Event('change'));
            }
        }(handleOnChange));
          ic.addEventListener('click',(ev)=>{
            ic.checked=!ic.checked;
          });
          o.listitemEl=op;
          list.appendChild(op);
        });
        div.listEl=listWrap;
  
        div.refresh=()=>{
          div.querySelectorAll('span.optext, span.placeholder').forEach(t=>div.removeChild(t));
          var sels=Array.from(el.selectedOptions);
          /*if(sels.length>(el.attributes['multiselect-max-items']?.value??1)){
            div.appendChild(newEl('span',{class:['optext','maxselected'],text:sels.length+' '+config.txtSelected}));
          }*/
          let selected = JSON.parse(sessionStorage.getItem('selectedTemplates'));
          selected = {...selected};
          console.log(selected);
          if(Object.keys(selected)?.length) {
            let passNames = getPassTemplatesNamesFromIds(selected);
            passNames = passNames?.length > 72 ? passNames?.substring(0, 72)?.concat('...') : passNames;

            //div.appendChild(newEl('span',{class:['optext','maxselected'],text:Object.keys(selected)?.length+' '+config.txtSelected}));
            div.appendChild(newEl('span',{class:['optext','maxselected'],text:passNames}));
          }
          /*if(sels.length>(el.attributes['multiselect-max-items']?.value??5)){
            div.appendChild(newEl('span',{class:['optext','maxselected'],text:sels.length+' '+config.txtSelected}));          
          }
          else{
            sels.map(x=>{
              var c=newEl('span',{class:'optext',text:x.text, srcOption: x});
              if((el.attributes['multiselect-hide-x']?.value !== 'true'))
                c.appendChild(newEl('span',{class:'optdel',text:'🗙',title:config.txtRemove, onclick:(ev)=>{c.srcOption.listitemEl.dispatchEvent(new Event('click'));div.refresh();ev.stopPropagation();}}));
  
              div.appendChild(c);
            });
          }*/
          if(0==el.selectedOptions.length) div.appendChild(newEl('span',{class:'placeholder',text:el.attributes['placeholder']?.value??config.placeholder}));
        };
        div.refresh();
      }
      el.loadOptions();
      
      search.addEventListener('input',()=>{
        list.querySelectorAll(":scope div:not(.multiselect-dropdown-all-selector)").forEach(d=>{
          var txt=d.querySelector("label").innerText.toUpperCase();
          d.style.display=txt.includes(search.value.toUpperCase())?'block':'none';
        });
      });
  
      div.addEventListener('click',()=>{
        div.listEl.style.display='block';
        search.focus();
        search.select();
      });
      
      document.addEventListener('click', function(event) {
        if (!div.contains(event.target)) {
          listWrap.style.display='none';
          div.refresh();
        }
      });    
    });
  }

  useEffect(() => {
    setTimeout(() => MultiselectDropdown(), 100);
  }, [passTemplatesData]);

  return (
    <>
          <select style={{width: '20em'}} name="field1" id="field1" multiple onChange={() => console.log(Array.from(this.selectedOptions).map(x=>x.value??x.text))} multiselect-hide-x="true">
            {
              passTemplatesData.map(({pasS_NEW_ID, pasS_NAME}, index) => (
                <option selected={selectedPassTemplates.includes(pasS_NEW_ID)} key={`passTemplatesData${index}`} value={pasS_NEW_ID}>{pasS_NAME}</option>
              ))
            }
          </select>
          <input type="text" hidden id={name} name={name}/>
          <div className="multiselect-dropdown"></div>
    </>
  );
}

