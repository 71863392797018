import { createSlice } from '@reduxjs/toolkit'

const passesSlice = createSlice({
  name: 'passes',
  initialState: {
    passes: [],
    updatePasses: 1,
    selectedPass: {}
  },
  reducers: {
    addGridData(state, action) {
      state.passes = [...action.payload];
    },
    updateGridData(state, action) {
      state.updatePasses = Math.floor(Math.random()*10000);
    },
    gridRowSelected(state, action) {
      state.selectedPass = {...action.payload}
    },
  },
})

export const { addGridData, updateGridData, gridRowSelected } = passesSlice.actions
export default passesSlice.reducer