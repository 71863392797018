import { createSlice } from '@reduxjs/toolkit'

const locationSlice = createSlice({
  name: 'locations',
  initialState: {
    locations: [],
    updatelocations: 0
  },
  reducers: {
    locationsAdded(state, action) {
      state.locations = [...action.payload]
    },
    locationsUpdated(state, action) {
      state.updatelocations = Math.floor(Math.random()*10000)
    },
  },
})

export const { locationsAdded, locationsUpdated } = locationSlice.actions
export default locationSlice.reducer