function ToggleSwitch({
    onChange,
    name,
    selected
}) {
  return (
    <label className="switch">
      <input type="checkbox" value="1" checked={selected} name={name} onChange={onChange}/>
      <span className="slider round"></span>
    </label>
  )
}

export default ToggleSwitch;