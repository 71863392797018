//Dependencies
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
//Components
import { BreadcrumbContext } from '../Layout/Layout';
import TableConfig from './passTypesTableConfig.json';
import Table from '../../atoms/Table/Table';
//API
import { getPassTypes } from '../dataAPI';
import { getCustomerLocationsData, getLocationsData } from '../Locations/locationsAPI';
import { getAllCustomersData } from '../Customers/AddCustomer/customerAPI';
import { getSitesData } from '../Sites/sitesAPI';
//Utilities
import { getUserData } from '../../utilities/app';
//slices
import { passTemplatesAdded } from '../../appSlices';
import { customersAdded } from '../Customers/customerSlice';
import { locationsAdded } from '../Locations/locationsSlice';
import { sitesAdded } from '../Sites/sitesSlice';
//Utilities
import { getCustomerIdOfPassTemplate, getLocationsPassTemplates } from '../../utilities/pass';
import classNames from 'classnames';

function PassTypes() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { header, visibleColumns, exportableColumns } = TableConfig;
  const passTemplatesData = useSelector(state => state.appData.passTemplates);
  const locations = useSelector(state => state.location.locations);
  const sites = useSelector(state => state.site.sites);
  const [originalGridData, setOriginalGridData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const customers = useSelector(state => state.customer.customers);
  const [customersData, setCustomersData] = useState(customers);
  const [selectedFilters, setSelectedFilters] = useState({
    customeR_ID: 'all'
  });

  const { userType, customerID, isCustomerUser } = getUserData();
  
  useEffect(() => {
    !userType && navigate('/signin');
  }, [userType]);

  const getCustomerName = (customerIDs) => {
    const customerNames = [];
    customerIDs.split(',').forEach(id => 
      customersData.forEach((customer, index) => {
        id === customer?.customeR_ID?.toString() && customerNames.push(`${customer.customeR_FIRSTNAME}`)
      })
    );
    return customerNames.toString();
  }

  const parseData = data => {
    return data.map(row => {
      return {
        ...row,
        isEmail: row.isEmail ? 'On' : 'Off',
        isSMS: row.isSMS ? 'On' : 'Off', 
        keyValue: row.keyValue ? JSON.parse(row.keyValue) : '',
        customeR_NAME: getCustomerName(getCustomerIdOfPassTemplate(sites, locations, row.pasS_NEW_ID))
      }
    })
  }

  const getPassTypesData = () => {
    setLoadingData(true);
    getPassTypes(
      (data) => {
        setLoadingData(false);
        dispatch(passTemplatesAdded(data));
        const formatedData = parseData([...data]);
        setOriginalGridData(formatedData);
        setGridData(formatedData);
      }
    );
  };

  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "Pass Types",
        "path": "/passtypes"
      }
    ]);
    (!sites || !sites.length ) && getSitesData(data => {
      dispatch(sitesAdded(data));
      !locations.length && getLocationsData(
        (locations) => {
          dispatch(locationsAdded(locations));
        }
      );
    }, customerID);
    (!passTemplatesData || !passTemplatesData.length) && getPassTypesData();
    (!customers || !customers.length) && getAllCustomersData(response => {
        dispatch(customersAdded(response));
        setCustomersData(response);
    });
  }, []);

  useEffect(() => {
    if(!passTemplatesData?.length) return
    if(isCustomerUser) {
      getCustomerLocationsData(
        customerID,
        (locations, selectedCustomer) => {
            const passTemplates = parseData(getLocationsPassTemplates(locations, passTemplatesData));
            setGridData(passTemplates);
            setOriginalGridData(passTemplates);
        }
      ) 
    } else {
      const data = parseData(passTemplatesData);
      setGridData(data);
      setOriginalGridData(data);
    }
  }, [passTemplatesData, locations]);

  const generateDataColumn = (key, value, row) => {
    if(key === 'keyValue') {
      return (
        <div className='keyValues-container'>
          {
            row.keyValue?.map(keyValue => (
              <div>
                {keyValue.key}: {keyValue.value}
              </div>
            ))
          }
        </div>
      ) 
    }
    return value;
  }

  const handleCustomerChange = (event) => {
      const customeR_ID = event.target.value;
      setSelectedFilters({
        customeR_ID
      });
      if(!customeR_ID) return;
      if(customeR_ID === 'all') {
          setGridData([...originalGridData]);
      } else {
          getCustomerLocationsData(
              customeR_ID,
              (locations, selectedCustomer) => {
                  const passTemplates = parseData(getLocationsPassTemplates(locations, passTemplatesData));
                  setGridData(passTemplates);
              }
          );
      }
  };

  const clearSelectedFilter = () => {
    setSelectedFilters({
      customeR_ID: 'all'
    });
    setGridData([...originalGridData]);
  }

  const renderTableFilters = () => (
    <div className="pass-types-filter">
      <label>Filter by</label>
      <select value={selectedFilters?.customeR_ID} name="customeR_ID" id="customeR_ID" onChange={handleCustomerChange}>
          <option value="">Select Customer*</option>
          <option value="all">All Customers</option>
          {
              customersData.map((customer, index) => (
                  <option key={`customeR_ID${index}`} value={customer.customeR_ID} selected>{customer.customeR_FIRSTNAME}</option>
              ))
          }
      </select>
      <button className="customers-filter-clear" onClick={clearSelectedFilter}>Clear Selected</button>
    </div>
  )

  return (
    <div className= {classNames({"pass-types": true, "pass-types-customer": isCustomerUser})}>
      <Table
        renderTableFilters={ !isCustomerUser ? renderTableFilters : null}
        TableTitle={`All Pass Types`}
        header={header}
        visibleColumns={visibleColumns}
        originalData={originalGridData}
        data={gridData}
        rowId={'pasS_ID'}
        exportableColumns={exportableColumns}
        generateDataColumn={generateDataColumn}
        loadingData={loadingData}
      />
    </div>
  );
};

export default PassTypes;