import { createSlice } from '@reduxjs/toolkit'
import TableConfig from './autoReportingTableConfig.json';

const { rows } = TableConfig;

const autoReportingSlice = createSlice({
  name: 'autoReporting',
  initialState: {
    reportings: [...rows],
    updateAutoReporting: 1,
    selectedAutoReporting: {},
  },
  reducers: {
    autoReportingAdded(state, action) {
      state.reportings = [...action.payload];
    },
    autoReportingUpdated(state, action) {
      state.updateAutoReporting = Math.floor(Math.random()*10000);
    },
    autoReportingSelected(state, action) {
      state.selectedAutoReporting = {...action.payload}
    }
  },
})

export const { autoReportingAdded, autoReportingUpdated, autoReportingSelected } = autoReportingSlice.actions
export default autoReportingSlice.reducer