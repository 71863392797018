import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbContext } from '../../Layout/Layout';
import TableConfig from './locationsTableConfig.json';
import { useDispatch, useSelector } from 'react-redux';
import { locationsAdded } from '../locationsSlice';
import { deleteLocation, viewEditLocation } from './locationsUtilities';
import { getLocationsData, getCustomerLocationsData } from '../locationsAPI';
import { getCustomersDropdownData } from '../../dataAPI';
import { customersDropdownDataAdded } from '../../../appSlices';
import { customerSelected } from '../../Customers/customerSlice';
import { getSitesData } from '../../Sites/sitesAPI';
import { sitesAdded } from '../../Sites/sitesSlice';
import Table from '../../../atoms/Table';
import { getUserData } from '../../../utilities/app';

function Locations() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const { header, customerHeader, exportableColumns, visibleColumns, customerExportableColumns, customerVisibleColumns } = TableConfig;
  const dispatch = useDispatch();
  const sites = useSelector(state => state.site.sites);
  const locations = useSelector(state => state.location.locations);
  const updatelocations = useSelector(state => state.location.updatelocations);
  const customersDropdownData = useSelector(state => state.appData.customers);
  const [originalGridData, setOriginalData] = useState([]);
  const [contactNameData, setContactNameData] = useState([]);
  const [sitesCustomers, setSitesCustomers] = useState([]);
  const [gridData, setData] = useState([]);
  const [customers, setCustomers] = useState(customersDropdownData);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const { isCustomerUser, customerID } = getUserData();

  useEffect(() => {
    isCustomerUser && !customerID && navigate('/dashboard');
  }, [customerID]);

  const getContactName = (sites) => {
    let contacts = [];
    sites.forEach(site => {
      !contacts.includes(site.sitE_CONTACT) && contacts.push(site.sitE_CONTACT);
    });
    setContactNameData([...contacts]);
  }

  const getLocationSiteData = (sitE_ID, dataKey) => {
    const locationSite = sites.find(site => parseInt(site.sitE_ID) === parseInt(sitE_ID));
    return locationSite ? locationSite[dataKey] : '';
  }

  const getCustomerName = (customeR_ID) => {
    const customer = customers.find(cust => parseInt(cust.customeR_ID) === parseInt(customeR_ID));
    return customer ? customer.customeR_NAME : '';
  }

  const setCustomerDropdownData = () => {
    const sitesCustomers = [];
    customers.forEach(customer => {
      const custFound = sites.find(site => parseInt(customer.customeR_ID) === parseInt(site.customeR_ID));
      if(custFound) {
        sitesCustomers.push({
          sitE_ID: custFound.sitE_ID,
          ...customer
        });
      }
    });
    setSitesCustomers(sitesCustomers);
  }

  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "Customers",
        "path": "/customers"
      },
      {
        "linkText": "Locations",
        "path": "/locations"
      }
    ]);
    (!customersDropdownData || !customersDropdownData.length) && getCustomersDropdownData(data => dispatch(customersDropdownDataAdded(data)));
    return () => {}
  }, []);

  useEffect(() => {
    (!sites || !sites.length ) && getSitesData(data => {
      dispatch(sitesAdded(data));
      refetchLocationsData();
    }, customerID);
  }, []);

  const refetchLocationsData = () => {
    setLoadingData(true);
    isCustomerUser ? getCustomerLocationsData(
      customerID,
      (locations, customerData) => {
        dispatch(customerSelected(customerData))
        dispatch(locationsAdded(locations));
        setLoadingData(false);
      }
    ) : getLocationsData(data => {
      dispatch(locationsAdded(data));
      setLoadingData(false);
    });
  }

  useEffect(() => {
    updatelocations && refetchLocationsData();
  }, [updatelocations]);

  useEffect(() => {
    setCustomers(customersDropdownData);
  }, [customersDropdownData]);

  useEffect(() => {
    getContactName(sites);
  }, [sites]);

  const updateLocationsData = (locations) => locations.map(row => {
      const customeR_NAME = getCustomerName(getLocationSiteData(row.sitE_ID, 'customeR_ID'));
      const sitE_NAME = getLocationSiteData(row.sitE_ID, 'sitE_NAME');
      const sitE_CONTACT = getLocationSiteData(row.sitE_ID, 'sitE_CONTACT');
      const emaiL_ID = getLocationSiteData(row.sitE_ID, 'emaiL_ID');
      const customeR_ID = getLocationSiteData(row.sitE_ID, 'customeR_ID');
      const phonE_NUMBER = getLocationSiteData(row.sitE_ID, 'phonE_NUMBER');
      
      return {
        ...row,
        customeR_NAME,
        sitE_NAME,
        sitE_CONTACT,
        emaiL_ID,
        customeR_ID,
        phonE_NUMBER
      }
    });

  useEffect(() => {
    const updatedLocationsData = updateLocationsData(locations);
    setData(updatedLocationsData);
    setOriginalData(updatedLocationsData);
  }, [locations]);

  const handleAddAction = () => navigate('/locations/add');

  const searchData = (searchKey) => {
    const searchCustomersData = [];
    originalGridData.forEach((row) => {
      const { logO_IMAGE, ...restCustomerData } = row;
      Object.values(restCustomerData)?.toString().toLowerCase().includes(searchKey.toLowerCase()) && searchCustomersData.push(row);
    });
    setData(searchCustomersData);
  }

  const clearSelectedFilter = () => {
    setSelectedFilters({
      locatioN_ID: '',
      sitE_ID: '',
      customeR_NAME: '',
      sitE_CONTACT: ''
    });
    setData([...originalGridData]);
  }

  const filterCustomerData = (filterByAttr, selectedValue, isSearch) => {
    setSelectedFilters({
      [filterByAttr]: selectedValue
    });

    if(!selectedValue) return setData(originalGridData);

    if(isSearch) {
      searchData(selectedValue);
      return;
    }

    const searchCustomersData = [];
    originalGridData.forEach((row) => {
      (row[filterByAttr]?.toString() === selectedValue) && searchCustomersData.push(row);
    });
    setData(searchCustomersData);
  };

  const viewEditSelectedLocation = (action, row, navigate) => {
    const selectedSites = sites.find(site => parseInt(site.sitE_ID) === parseInt(row?.sitE_ID));
    localStorage.setItem('selectedSiteId', row.sitE_ID);
    localStorage.setItem('selectedSite', JSON.stringify(selectedSites));
    localStorage.setItem('selectedCustomerId', selectedSites.customeR_ID);
    viewEditLocation(action, row, navigate);
  }

  const generateDataColumn = (key, value, row) => {
    if(key === "actions") return (
      <div className="locations-grid-row-actions">
        <button onClick={() => viewEditSelectedLocation('view', row, navigate)}>
          <img src="/assets/customers/view.png"/>
        </button>
        <button onClick={() => viewEditSelectedLocation('edit', row, navigate)}>
          <img src="/assets/customers/edit.png"/>
        </button>
        <button onClick={() => deleteLocation(row, refetchLocationsData)}>
          <img src="/assets/customers/delete.png"/>
        </button>
      </div>
    );

    return value;
  }

  const renderTableFilters = () => (
    <div className="locations-filter">
      <label>Filter by</label>
      <select name="siteFilter" value={selectedFilters.sitE_ID} id="state" onChange={(e) => filterCustomerData('locatioN_ID', e.target.value)}>
        <option value="">Locations</option>
        {
          originalGridData.map(location => (
            <option value={location.locatioN_ID}>{location.locatioN_NAME}</option>
          ))
        }
      </select>
      <label>Filter by</label>
      <select name="siteFilter" value={selectedFilters.sitE_ID} id="state" onChange={(e) => filterCustomerData('sitE_ID', e.target.value)}>
        <option value="">Site Name</option>
        {
          sites.map(site => (
            <option value={site.sitE_ID}>{site.sitE_NAME}</option>
          ))
        }
      </select>
      { !isCustomerUser ? (
        <>
          <label>Filter by</label>
          <select name="customerFilter" id="customer" value={selectedFilters.customeR_ID} onChange={(e) => filterCustomerData('customeR_NAME', e.target.value, true)}>
            <option value="">Customer Name</option>
            {
              customers?.map(customer => (
                <option value={customer.customeR_NAME}>{customer.customeR_NAME}</option>
              ))
            }
          </select>
        </>
        ) : <></>
      }
      <label>Filter by</label>
      <select name="contactFilter" id="contact" value={selectedFilters.sitE_CONTACT} onChange={(e) => filterCustomerData('sitE_CONTACT', e.target.value, true)}>
        <option value="">Contact Name</option>
        {
          contactNameData.map(name => (
            <option value={name}>{name}</option>
          ))
        }
      </select>
      <button className="locations-filter-clear" onClick={clearSelectedFilter}>Clear Selected</button>
    </div>
  );

  return (
    <div className="locations">
      <Table
        renderTableFilters={renderTableFilters}
        handleAddAction={handleAddAction}
        TableTitle={`Locations`}
        originalData={originalGridData}
        header={isCustomerUser ? customerHeader : header}
        visibleColumns={isCustomerUser ? customerVisibleColumns : visibleColumns}
        exportableColumns={isCustomerUser ? customerExportableColumns : exportableColumns}
        data={gridData}
        rowId={'locatioN_ID'}
        generateDataColumn={generateDataColumn}
        loadingData={loadingData}
      />
    </div>
  );
};

export default Locations;