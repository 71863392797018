import { useEffect, useContext } from "react";
//Components
import { BreadcrumbContext } from '../Layout/Layout';

function Campaigns() {
    const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
    useEffect(() => {
        setCurrentBreadcrumb([
          {
            "linkText": "Campaigns",
            "path": "/campaigns"
          }
        ]);
        return () => {}
      }, []);
    return (
        <div>
            <h3 style={{color: '#fff', marginTop: '30px'}}>Campaigns feature coming soon</h3>
        </div>
    )
};

export default Campaigns;