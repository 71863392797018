import { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputTextfield from '../../atoms/textfield';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import classNames from 'classnames';
import { validateForm } from '../../utilities/form';
import { BreadcrumbContext } from '../Layout/Layout';
import { getUserData } from '../../utilities/app';
import { baseURL, addPassTypesURL } from '../../constants/apiUrl';
import { getCustomerLocationsData } from '../Locations/locationsAPI';
import { getLocationsPassTemplates } from '../../utilities/pass';
import { getAllCustomersData } from '../Customers/AddCustomer/customerAPI';
import { customersAdded } from '../Customers/customerSlice';
import { getPassTypes } from '../dataAPI';
import { passTemplatesAdded } from '../../appSlices';

function PassInformation() {
    const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginBtnRef = useRef(null);
    const [formData, setFormData] = useState({
      customeR_ID: '',
      passTemplateId: '',
      URL: '',
      isSMS: false,
      isEmail: false
    });
    const [errors, setErrors] = useState({});
    const { isCustomerUser, customerID } = getUserData();
    const customers = useSelector(state => state.customer.customers);
    const [customersData, setCustomersData] = useState(customers);
    const passTemplatesData = useSelector(state => [...state.appData.passTemplates]);
    const [customerPassTemplatesData, setCustomerPassTemplatesData] = useState([]);
    const [selectedPassTemplate, setSelectedPassTemplate] = useState(null);
    const [keyValueData, setKeyValueData] = useState([{
        key: '',
        value: '',
    }]);
    
    useEffect(() => {
        isCustomerUser && !customerID && navigate('/dashboard');
    }, [customerID]);

    const fetchPassTemplates = () => getPassTypes(response => dispatch(passTemplatesAdded(response)));

    useEffect(() => {
        (!passTemplatesData || !passTemplatesData.length) && fetchPassTemplates();
        (!customers || !customers.length) && getAllCustomersData(response => {
            dispatch(customersAdded(response));
            setCustomersData(response);
        });
    }, []);

    useEffect(() => {
      setCurrentBreadcrumb([
        {
          "linkText": "Pass Type Information",
          "path": "/passinformation"
        }
      ]);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckboxChange = (value, name) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const resetFormData = () => {
        setFormData({
            customeR_ID: '',
            passTemplateId: '',
            URL: '',
            keyValue: ''
        });
        setKeyValueData([{
            key: '',
            value: ''
        }]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { keyValue, ...restFormData } = formData;
        const newErrors = validateForm(restFormData);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            // Form submission logic here
            formData.keyValue = JSON.stringify(keyValueData);
            try {
                loginBtnRef.current.classList.add("button-loading");
                const response = await axios.request({
                    baseURL,
                    url: `${addPassTypesURL}/${selectedPassTemplate?.pasS_NEW_ID}`,
                    method: 'post',
                    data: {
                        ...selectedPassTemplate,
                        name: selectedPassTemplate?.pasS_NAME,
                        uniqueIdentifier: selectedPassTemplate?.pasS_NEW_ID,
                        ...formData
                    }
                });
                toast("Pass information updated successfully.")
                loginBtnRef.current.classList.remove("button-loading");
                fetchPassTemplates();
                resetFormData();
            }
            catch(e) {
                loginBtnRef.current.classList.remove("button-loading");
                toast('Pass information update failed, please try again after some time.')
            }
        } else {
            toast('All the input fileds marked with * are mandatory to fill.');
        }
    };

    const handleCustomerChange = (event) => {
        const customeR_ID = event.target.value;
        if(!customeR_ID) return;
        if(customeR_ID === 'all') {
            setCustomerPassTemplatesData([...passTemplatesData]);
        } else {
            getCustomerLocationsData(
                customeR_ID,
                (locations, selectedCustomer) => {
                    const passTemplates = getLocationsPassTemplates(locations, passTemplatesData);
                    setCustomerPassTemplatesData(passTemplates);
                }
            );
        }
        handleChange(event);
    };

    const handlePassTypeChange = (event) => {
        const passTemplateId = event.target.value;
        const passTemplate = customerPassTemplatesData.find(({pasS_NEW_ID}) => pasS_NEW_ID === passTemplateId);

        setFormData({
            URL: passTemplate.url || '-',
            isSMS: passTemplate.isSMS,
            isEmail: passTemplate.isEmail,
            passTemplateId,
            keyValue: passTemplate.keyValue ? JSON.parse(passTemplate.keyValue) : ''
        });
        passTemplate.keyValue ? setKeyValueData(JSON.parse(passTemplate.keyValue)) : setKeyValueData([{key: '', value: ''}]);
        setSelectedPassTemplate(passTemplate);
    }

    const handleKeyValueCountChange = () => {
        const keyValues = [...keyValueData];
        //if (contacts.length > 3) return;
        keyValues.push({
            key: '',
            value: '',
        })
        setKeyValueData([...keyValues]);
    };

    const handleKeyValueDetailsChange = (keyIndex, fieldName, changedValue) => {
        const keyValues = [...keyValueData];
        keyValues[keyIndex][fieldName] = changedValue;
        setKeyValueData([...keyValues]);
    };

    const removeKeyValue = (keyIndex) => {
        const keyValues = [...keyValueData];
        keyValues?.splice(keyIndex, 1);
        setKeyValueData([...keyValues]);
    };

    const renderkeyValuesDetails = () => {
        const keyValues = [];
        for (let index = 0; index < keyValueData?.length; index++) {
          keyValues.push(
            <div key={`keyValues${index}`} className="pass-information-notification-container">
                <div className="pass-information-notification" style={{marginRight: '50px'}}>
                    <div className="pass-information-form-input">
                        <InputTextfield
                            type="text"
                            hideError={true}
                            value={keyValueData[index]?.key}
                            name={`key${index}`}
                            placeholder="Enter key name"
                            onChange={(e) => handleKeyValueDetailsChange(index, 'key', e.target.value)}/>
                    </div>
                </div>
                <div className="pass-information-notification">
                    <div className="pass-information-form-input">
                        <InputTextfield
                            type="text"
                            hideError={true}
                            value={keyValueData[index]?.value}
                            name={`value${index}`}
                            placeholder="Enter value"
                            onChange={(e) => handleKeyValueDetailsChange(index, 'value', e.target.value)}/>
                    </div>
                </div>
                {index >= 0 ? (
                    <button onClick={e => removeKeyValue(index)}>
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                ) : null }
            </div>
          )
        }
  
        return keyValues;
      }

    return (
        <div className="pass-information">
            <div className="pass-information-heading">
                <div className="pass-information-heading-text">
                    Pass Information
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="pass-information-form">
                    <div className="pass-information-form-content">
                        { !isCustomerUser ? (
                            <div className="pass-information-form-input pass-information-form-input-select">
                                <select value={formData?.customeR_ID} name="customeR_ID" id="customeR_ID" onChange={handleCustomerChange}>
                                    <option value="">Select Customer*</option>
                                    <option value="all">All Customers</option>
                                    {
                                        customersData.map((customer, index) => (
                                            <option key={`customeR_ID${index}`} value={customer.customeR_ID} selected>{customer.customeR_FIRSTNAME}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            ) : <></>
                        }
                    
                        <div className="pass-information-form-input pass-information-form-input-select">
                            <select value={formData?.passTemplateId} name="passTemplateId" id="passTemplateId" onChange={handlePassTypeChange}>
                                <option value="">Select Pass Types*</option>
                                {
                                    customerPassTemplatesData?.map((passTemplate, index) => (
                                        <option key={`passTemplatesData${index}`} value={passTemplate.pasS_NEW_ID}>{passTemplate.pasS_NAME}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="pass-information-form-input">
                            <InputTextfield type="text" hideError={true} value={formData?.URL} name="URL" placeholder="Claim Page URL*" error={errors.URL} onChange={handleChange}/>
                        </div>
                        <div className="pass-information-notification-container">
                            <div className="pass-information-notification">
                                <label>
                                    <input type="checkbox" checked={formData?.isSMS} onChange={(e) => handleCheckboxChange(e.target.checked, 'isSMS')}/>
                                    <span className={classNames({checkmark: true, selected: formData?.isSMS})}>SMS Notification</span>
                                </label>
                            </div>
                            <div className="pass-information-notification">
                                <label>
                                    <input type="checkbox" checked={formData?.isEmail} onChange={(e) => handleCheckboxChange(e.target.checked, 'isEmail')}/>
                                    <span className={classNames({checkmark: true, selected: formData?.isEmail})}>Email Notification</span>
                                </label>
                            </div>
                        </div>
                        <div className="pass-information-heading">
                            <div className="pass-information-heading-text">
                                Additional data fileds
                            </div>
                        </div>
                        {renderkeyValuesDetails()}
                        <div className="pass-information-form-add-more" onClick={handleKeyValueCountChange}>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                            Add more
                        </div>        
                    </div>
                </div>
                
                <div className="change-password-form-btn change-password-form-login-btn">
                    <button type="submit" ref={loginBtnRef}>
                        <span className="button__text">Save</span>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default PassInformation;