import { useContext, useEffect } from 'react';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
//Components
import InputTextfield from '../../../atoms/textfield';
import { validateForm } from '../../../utilities/form';
import { BreadcrumbContext } from '../../Layout/Layout';
import MultiSelectDropdown from '../../../atoms/MultiSelectDropdown';
import CountryDropdown from '../../../atoms/CountryDropdown';
//Constants, utilities, mockdata
import { getUserData } from '../../../utilities/app';
import siteTimingsConfig from './siteTimingConfig.json';
import { addReportURL, baseURL } from '../../../constants/apiUrl';
//Slice
import { locationsAdded, locationsUpdated } from '../../Locations/locationsSlice';
import { customersDropdownDataAdded } from '../../../appSlices';
import { sitesAdded } from '../../Sites/sitesSlice';
//API
import { getSitesData } from '../../Sites/sitesAPI';
import { getLocationsData } from '../../Locations/locationsAPI';
import { getCustomersDropdownData, getPassTypes } from '../../dataAPI';

function RunReport({
  isEditReport,
  selectedReport
}) {
    const submitBtnRef = useRef(null);
    const dispatch = useDispatch();
    const editReport = isEditReport || window.location.pathname.includes('/locations/edit');
    const navigate = useNavigate();
    const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
    const sites = useSelector(state => state.site.sites);
    const locations = useSelector(state => state.location.locations);
    const [sitesData, setSitesData] = useState(sites);
    const customersDropdownData = useSelector(state => state.appData.customers);
    const [customerSitesData, setCustomerSitesData] = useState([]);
    const [customers, setCustomers] = useState(customersDropdownData);
    const [selectedSiteLocations, setSelectedSiteLocations] = useState([]);
    const [recipients, setRecipients] = useState([
        {
          "recipientID": "1",
          "recipientNAME": "Westfield@gmail.com"
        }
    ])
    const [dayTimingsArr, setDayTimingsArr] = useState([
      ...siteTimingsConfig
    ]);
    const [formData, setFormData] = useState({
      customeR_ID: '',
      sitE_ID: '',
      locatioN_ID: '',
      status: '',
      reportName:  '',
      frequency:  '',
      recipientIDs:  '',
      exportType:  '',
    });
    const [errors, setErrors] = useState({});
    const { isCustomerUser, customerID } = getUserData();

    useEffect(() => {
      isCustomerUser && !customerID && navigate('/dashboard');
    }, [customerID]);

    useEffect(() => {
      !editReport && setCurrentBreadcrumb([
        {
          "linkText": "Reporting",
          "path": "/reports"
        },
        {
          "linkText": "Run Report",
          "path": "/runreport"
        }
      ]);
      (!customersDropdownData || !customersDropdownData.length) && getCustomersDropdownData(data => dispatch(customersDropdownDataAdded(data)));
      (!locations || !locations?.length) && getLocationsData(data => dispatch(locationsAdded(data)))
    }, []);
  
    useEffect(() => {
      if(!sites || !sites.length) {
        getSitesData(data => dispatch(sitesAdded(data)), customerID);
      } else {
        setSitesData(sites);
      }
    }, [sites]);

    const initializeFormData = (selectedReportData) => {
      if(!selectedReportData) return;
      const {
        createD_BY,
        updateD_BY,
        creatioN_DATE,
        updatE_DATE,
        locationTiming,
        locationHardwares,
        ...selectedReport
      } = selectedReportData;

      const selectedSites = sitesData.find(site => parseInt(site.sitE_ID) === parseInt(selectedReport?.sitE_ID));
      setSitesDropdownData(selectedSites?.customeR_ID);
      setFormData({
        ...selectedReport,
        customeR_ID: selectedSites?.customeR_ID || '',
        sitE_ID: selectedReport?.sitE_ID || '',
        locatioN_ID: selectedReport?.locatioN_ID || '',
        via: selectedReport?.via || '',
        nearby:  selectedReport?.nearby || '',
        level:  selectedReport?.level || '',
        capacity:  selectedReport?.capacity || '',
        pasS_TYPES:  '-',
        pasS_TEMPLATE_ID: selectedReport.pasS_TEMPLATE_ID || '',
        dooR_GUID: selectedReport.dooR_GUID || '-',
      });

      if(!locationTiming || !locationTiming.length) return;

      const mergedSavedDayTimings = dayTimingsArr.map((configDay) => {
        const isDayLastSaved = locationTiming.find(savedDay => savedDay?.oP_DAY?.toLowerCase() == configDay?.oP_DAY?.toLowerCase());
        return isDayLastSaved ? {...isDayLastSaved, isSelected: true} : {...configDay}
      });
      setDayTimingsArr(mergedSavedDayTimings);
    }

    useEffect(() => {
      if(!selectedReport) return;
      editReport && initializeFormData(selectedReport);
    }, []);

    useEffect(() => {
      setCustomers(customersDropdownData);
    }, [customersDropdownData]);

    useEffect(() => {
      isCustomerUser && customerID && (formData.customeR_ID !== customerID) && handleCustomerChange({target: {value: customerID, name: "customeR_ID", type: "input"}});
    }, [sitesData]);

    const setSitesDropdownData = (customeR_ID) => {
      const customerSites = sitesData.filter(site => parseInt(site.customeR_ID) === parseInt(customeR_ID));
      setCustomerSitesData(customerSites);
    }

    const handleCustomerChange = (e) => {
      const { value } = e.target;
      setFormData({
        ...formData,
        sitE_ID: '',
      });
      setSitesDropdownData(value);
      handleChange(e);
    }

    const handleSiteChange = (e) => {
      const { value } = e.target;
      const siteLocations = locations.filter(location => location.sitE_ID === parseInt(value))
      setSelectedSiteLocations(siteLocations)
      handleChange(e);
    }

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        let changedValue = value;
        if(type === 'checkbox') {
          changedValue = e.target.checked ? 1 : 0;
        }

        setFormData({
            ...formData,
            [name]: changedValue,
        });
    };

    const handleSubmit = async (e) => {
      return;
      e.preventDefault();
      formData.locationTiming = dayTimingsArr.filter(dayTiming => dayTiming.isSelected);
      const locationHardwares = {
        ...formData.locationHardwares
      };
      delete formData.locationHardwares;
      formData.locationHardwares = [{...locationHardwares}];
      formData.iS_HARDWARE = formData.iS_HARDWARE ? 1 : 0;
      formData.pasS_TEMPLATE_ID = document.getElementById('passTEMPLATEIDs').value;
      const newErrors = validateForm(formData, {email: /\S+@\S+\.\S+/});
      setErrors(newErrors);


      if (Object.keys(newErrors).length === 0) {
        try {
              submitBtnRef.current.classList.add("button-loading");
              await axios.request({
                  baseURL,
                  url: editReport ? `${addReportURL}/${selectedReport?.reportID}` : addReportURL,
                  method: 'post',
                  data: formData
              });
              dispatch(locationsUpdated());
              editReport ? toast("Report details updated successfully.") : toast("Report scheduled successfully.");
              submitBtnRef.current.classList.remove("button-loading");
              
              navigate("/reports");
          }
          catch(e) {
              submitBtnRef.current.classList.remove("button-loading");
              toast('Something went wrong, please try again later.');
          }
      } else {
        toast('All the input fileds marked with * are mandatory to fill.');
      }
    };

    const handleTimingsChange = (value, index, key) => {
      dayTimingsArr[index][key] = value;

      setDayTimingsArr([
        ...dayTimingsArr,
      ])
    }

    const renderDayTimings = () => {
      const days = [];
      for (let index = 0; index < dayTimingsArr?.length - 1; index++) {
        days.push(
          <div className="run-report-timings-day">
            <label className="run-report-timings-container">
              <input type="checkbox" checked={dayTimingsArr[index].isSelected} onChange={(e) => handleTimingsChange(e.target.checked, index, 'isSelected')}/>
              <span className={classNames({checkmark: true, selected: dayTimingsArr[index].isSelected})}>{dayTimingsArr[index].oP_DAY}</span>
            </label>
          </div>
        )
      }
      return days;
    }

    const renderSchedule = () => (
      <>
        <div className="run-report-heading run-report-heading-hardware">
          <div className="run-report-heading-text">
            Days Scheduled
          </div>
        </div>
        
        <div className="run-report-timings-row">
          {renderDayTimings()}
        </div>
        <div className="run-report-timings-row">
          <div className="run-report-timings-day">
            <label className="run-report-timings-container">
              <input type="checkbox" checked={dayTimingsArr[6].isSelected} onChange={(e) => handleTimingsChange(e.target.checked, 6, 'isSelected')}/>
              <span className={classNames({checkmark: true, selected: dayTimingsArr[6].isSelected})}>{dayTimingsArr[6].oP_DAY}</span>
            </label>
          </div>
        </div>
        
        <div className="run-report-heading run-report-heading-hardware">
          <div className="run-report-heading-text">
            Time Scheduled
          </div>
        </div>
        <div className="run-report-form-input run-report-form-input-time">
            <InputTextfield
              type="time"
              name="starT_DATETIME"
              value={formData?.time}
              placeholder="Time"
              onChange={handleChange}/>
        </div>
      </>
    );

    return (
      <div className="run-report">
        <div className="run-report-heading">
          <div className="run-report-heading-text">
            {editReport ? 'Edit Report' : 'Run Report'}
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="run-report-form">
            <div className="run-report-form-content">
              { !isCustomerUser ? (
                  <div className="run-report-form-input run-report-form-input-select">
                    <select value={formData?.customeR_ID} name="customeR_ID" id="customeR_ID" onChange={handleCustomerChange}>
                      <option value="">Select Customer*</option>
                      {
                        customers.map((customer, index) => (
                          <option key={`customeR_ID${index}`} value={customer.customeR_ID} selected>{customer.customeR_NAME}</option>
                        ))
                      }
                    </select>
                  </div>
                ) : <></>
              }

              <div className="add-location-form-input add-location-form-input-select">
                <MultiSelectDropdown
                  name="sitE_IDs"
                  preSelectedOptions={ editReport ? formData?.sitE_IDs : ''}
                  dropdownData={sites}
                  optionID="sitE_ID"
                  optionNAME="sitE_NAME"
                  placeholder="Select Sites*"
                  txtSelected="Sites Selected"
                />
              </div>

              <div className="run-report-form-input run-report-form-input-select">
                <select value={formData?.locatioN_ID} name="locatioN_ID" id="locatioN_ID" onChange={handleChange}>
                  <option value="">Select Location*</option>
                  {
                    locations?.map((location, index) => (
                      <option key={`location${index}`} value={location.locatioN_ID}>{location.locatioN_NAME}</option>
                    ))
                  }
                </select>
              </div>

              <div className="run-report-form-input run-report-form-input-select">
                <select value={formData?.status} name="status" id="status" onChange={handleChange}>
                  <option value="">Select Status*</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>

              <div className="run-report-form-input run-report-datetime">
                  <div className="run-report-datetime-date">
                    <div className="run-report-heading run-report-heading-hardware">
                      Select Start Date*
                    </div>
                    <div>
                      <InputTextfield type="date" hideError={true} name="endDate" value={formData?.endDate} placeholder="Select End Date*" error={errors.endDate} onChange={handleChange}/>
                    </div>
                  </div>
                  <div>
                    <div className="run-report-heading run-report-heading-hardware">
                      <div className="run-report-heading-text">
                        Start Time*
                      </div>
                    </div>
                    <div className="run-report-form-input run-report-form-input-time">
                        <InputTextfield
                          type="time"
                          name="starT_DATETIME"
                          value={formData?.time}
                          placeholder="Time"
                          onChange={handleChange}/>
                    </div>
                  </div>
              </div>

              <div className="run-report-form-input run-report-datetime">
                  <div className="run-report-datetime-date">
                    <div className="run-report-heading run-report-heading-hardware">
                      Select End Date*
                    </div>
                    <div>
                      <InputTextfield type="date" hideError={true} name="endDate" value={formData?.endDate} placeholder="Select End Date*" error={errors.endDate} onChange={handleChange}/>
                    </div>
                  </div>
                  <div>
                    <div className="run-report-heading run-report-heading-hardware">
                      <div className="run-report-heading-text">
                        End Time*
                      </div>
                    </div>
                    <div className="run-report-form-input run-report-form-input-time">
                        <InputTextfield
                          type="time"
                          name="starT_DATETIME"
                          value={formData?.time}
                          placeholder="Time"
                          onChange={handleChange}/>
                    </div>
                  </div>
              </div>

              <div className="run-report-form-input run-report-form-input-select">
                <select value={formData?.exportType} name="exportType" id="exportType" onChange={handleChange}>
                  <option value="">Export Type*</option>
                  <option value="csv">CSV</option>
                  <option value="xls">XLS</option>
                  <option value="pdf">PDF</option>
                </select>
              </div>

              <div className="run-report-form-btn run-report-form-btn-submit">
                <button type="submit" ref={submitBtnRef}>
                    <span className="button__text">Run Now</span>
                </button>
              </div>
            </div>
          </div>
        </form>
        <CountryDropdown displayDropdown={false}/>
      </div>
    );
};

export default RunReport;