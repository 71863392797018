import axios from "axios";
import { baseURL, getAllCustomersURL, getCustomersURL } from "../../../constants/apiUrl";

export const getCustomerData = async (customerId, callbackFn) => {
  if(!customerId) return;

  try {
    const response = await axios.request({
        baseURL,
        url: `${getCustomersURL}/${customerId}`,
        method: 'get',
    });
    callbackFn(response?.data);
  }
  catch(e) {
    console.log('Something went wrong in Customer Data api, please try again later.')
  }
};

export const getAllCustomersData = async (callbackFn) => {
  try {
    const response = await axios.request({
        baseURL,
        url: getAllCustomersURL,
        method: 'get',
    });
    callbackFn(response?.data);
  }
  catch(e) {
  }
};