import axios from "axios";
import { bookingsBaseURL, getAllBookingsURL } from "../../constants/apiUrl";

export const getAllBookings = async (startDate, endDate, listType, callbackFn) => {
  try {
    const response = await axios.request({
      baseURL: bookingsBaseURL,
      url: `${getAllBookingsURL}?periodFrom=${startDate}&periodTo=${endDate}&listType=${listType}`, //?periodFrom={{dateTimeFrom}}&periodTo={{dateTimeTo}}&listType={{listType}}&dataOffset={{dataStart}}&dataLimit={{dataSize}}
      method: 'get',
    });
    const bookings = [
      ...response?.data?.data
    ];
    callbackFn(bookings);
  }
  catch(e) {
    console.log('Something went wrong with pass templates fetching, please try again later.')
  }
};
