//Dependencies
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//Components
import { BreadcrumbContext } from '../Layout/Layout';
import TableConfig from './bookingsTableConfig.json';
import Table from '../../atoms/Table/Table';
//API
import { getAllBookings } from './bookingsAPI';
import { getCustomersDropdownData } from '../dataAPI';
//Utilities
import { getUserData } from '../../utilities/app';
//slices
import { bookingsAdded, bookingSelected, filtersAdded, getFormattedDate } from './bookingsSlice';
import { customersDropdownDataAdded } from '../../appSlices';

function Bookings() {
  const { setCurrentBreadcrumb } = useContext(BreadcrumbContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { header, visibleColumns, exportableColumns } = TableConfig;
  const bookings = useSelector(state => state.booking.bookings);
  const filters = useSelector(state => state.booking.selectedFilters);
  const [originalGridData, setOriginalGridData] = useState(bookings);
  const [gridData, setGridData] = useState(bookings);
  const [loadingData, setLoadingData] = useState(false);
  const customersDropdownData = useSelector(state => state.appData.customers);
  const [customers, setCustomers] = useState(customersDropdownData);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const [selectedFilters, setSelectedFilters] = useState({...filters});
  const { userType, customerID, isCustomerUser } = getUserData();
  
  useEffect(() => {
    !userType && navigate('/signin');
  }, [userType]);

  useEffect(() => {
    setCustomers(customersDropdownData);
  }, [customersDropdownData]);


  const getBookingsData = () => {
    setLoadingData(true);
    getAllBookings(
      selectedFilters.startDate,
      selectedFilters.endDate,
      'all',
      (data) => {
        setLoadingData(false);
        dispatch(bookingsAdded(data));
      }
    );
  };

  useEffect(() => {
    setCurrentBreadcrumb([
      {
        "linkText": "Customers",
        "path": "/customers"
      },
      {
        "linkText": "All Bookings",
        "path": "/bookings"
      }
    ]);
    (!customersDropdownData || !customersDropdownData.length) && getCustomersDropdownData(data => dispatch(customersDropdownDataAdded(data)));
    return () => {}
  }, []);

  const parseBookingData = data => {
    return data.map(row => {
      return {
        ...row,
        name: row.guests && row.guests?.length && `${row.guests[0]?.firstname} ${row.guests[0]?.lastname}`,
        email: row.guests && row.guests?.length && `${row.guests[0]?.contact_details[1]?.content}`,
        phone: row.guests && row.guests?.length && `${row.guests[0]?.contact_details[0]?.content}`,
        address: row.guests && row.guests?.length && `${row.guests[0]?.street} ${row.guests[0]?.city} ${row.guests[0]?.state} ${row.guests[0]?.country} ${row.guests[0]?.postcode}`,
      }
    })
  }

  useEffect(() => {
    const data = parseBookingData(bookings);
    setOriginalGridData(data);
    setGridData(data);
    startDateRef.current.valueAsDate = new Date(selectedFilters.startDate);
    endDateRef.current.valueAsDate = new Date(selectedFilters.endDate);
  }, [bookings]);

  useEffect(() => {
    (!bookings || !bookings.length) && getBookingsData();
  }, []);

  const viewEditRow = (opType, row) => {
    localStorage.setItem('selectedBooking', JSON.stringify(row));
    dispatch(bookingSelected(row));
    navigate(`/bookingview`);
  }

  const setTableFilter = (event) => {
    const { name, value, type } = event.target;
    const formattedDateValue = type === 'date' ? getFormattedDate(value) : value;
    setSelectedFilters({
      ...selectedFilters,
      [name]: formattedDateValue
    });
    dispatch(filtersAdded({
      ...selectedFilters,
      [name]: formattedDateValue
    }))
  };

  const generateDataColumn = (key, value, row) => {
    if(key === "actions") return (
      <div className="bookings-grid-row-actions">
        <button onClick={() => viewEditRow('view', row)}>
          <img src="/assets/customers/view.png"/>
        </button>
      </div>
    );

    return value;
  }

  const clearSelectedFilter = () => {
    setSelectedFilters({
      startDate: '',
      endDate: ''
    });
    setGridData([...originalGridData]);
  }

  const renderTableFilters = () => (
    <div className="bookings-filter">
      { !isCustomerUser ? (
        <>
          <label>Filter by</label>
          <select name="customeR_ID" id="customer" value={selectedFilters.customeR_ID} onChange={setTableFilter}>
            <option value="">Customer Name</option>
            {
              customers?.map(customer => (
                <option value={customer.customeR_ID}>{customer.customeR_NAME}</option>
              ))
            }
          </select>
        </>
        ) : <></>
      }
      <label>Booking Start Date</label>
      <input type="date" name="startDate" id="startDate" valueAsDate={selectedFilters.startDate} ref={startDateRef} onChange={setTableFilter}/>

      <label>Booking End Date</label>
      <input type="date" name="endDate" valueAsDate={selectedFilters.endDate} ref={endDateRef} onChange={setTableFilter}/>

      <button className="bookings-filter-clear" onClick={getBookingsData}>Apply Filter</button>
    </div>
  );
  //<button className="bookings-filter-clear" onClick={clearSelectedFilter}>Clear Selected</button>


  return (
    <div className="bookings">
      <Table
        renderTableFilters={renderTableFilters}
        TableTitle={`All Bookings`}
        header={header}
        visibleColumns={visibleColumns}
        originalData={originalGridData}
        data={gridData}
        rowId={'booking_id'}
        exportableColumns={exportableColumns}
        generateDataColumn={generateDataColumn}
        loadingData={loadingData}
      />
    </div>
  );
};

export default Bookings;